import { CheckCircleOutlined } from "@ant-design/icons";
import { DatePicker, Form, Input, InputNumber, Spin } from "antd";
import { useState } from "react";
import ReactPasswordChecklist from "react-password-checklist";
import checkBox from "../../../assets/svg/Checkbox.svg";
import { useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import dayjs from "dayjs";

export default function PasswordStep({ handleBack, form }) {
  const { authLoading, saving } = useSelector((state) => state.auth);

  const today = new Date();
  let dd = String(today.getDate()).padStart(2, 0);
  let mm = String(today.getMonth() + 1).padStart(2, 0);
  let yyyy = today.getFullYear() - 18;

  let minDate = `${yyyy}-${mm}-${dd}`;

  const [password, setpassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isValid, setisValid] = useState(false);

  const handlePrev = async () => {
    await handleBack();
    await form.setFieldsValue({ usrEncryptedPassword: null });
    await setConfirmPassword("");
  };

  return (
    <>
      <div className="flex flex-col w-full h-full">
        <div className="grid grid-cols-2 gap-x-5">
          <Form.Item
            label="Administrator First Name"
            name="usrFirstName"
            rules={[
              {
                required: true,
                message: "Administrator first name is required",
              },
            ]}
          >
            <Input className="input" />
          </Form.Item>
          <Form.Item
            label="Administrator Last Name"
            name="usrLastName"
            rules={[
              {
                required: true,
                message: "Administrator Last name is required",
              },
            ]}
          >
            <Input className="input" />
          </Form.Item>
          <Form.Item
            name="usrEmail"
            label="Admin Email"
            rules={[
              {
                required: true,
                message: "Admin Email is required to login",
              },
              {
                type: "email",
                message: "Please enter a valid Admin email address",
              },
            ]}
          >
            <Input type="email" className="input" />
          </Form.Item>

          <Form.Item
            name="usrNationalId"
            label="National ID"
            rules={[
              {
                required: true,
                message: "Admin National ID is required",
              },
              {
                validator(_, value) {
                  if (value && value.toString().length < 6) {
                    return Promise.reject(
                      "National ID must be at least 6 characters long"
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <InputNumber
              min={0}
              parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
              className="input flex"
            />
          </Form.Item>
          <Form.Item
            name="usrDob"
            rules={[
              {
                required: false,
                message: "Field is required",
              },
            ]}
            label="Date of Birth"
          >
            <DatePicker
              maxDate={dayjs(minDate)}
              format={"YYYY MMM DD"}
              className="input"
            />
          </Form.Item>

          <Form.Item
            label="Admin Phone"
            name="usrMobileNumber"
            validateFirst
            rules={[
              {
                required: true,
                message: "Admin phone number is required",
              },
              {
                validator: (_, value) => {
                  if (value && value.length >= 12) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "Phone number must be exactly 10 characters excluding country code"
                    )
                  );
                },
              },
            ]}
          >
            <PhoneInput
              enableSearch
              country="ke"
              countryCodeEditable={false}
              className="input"
            />
          </Form.Item>
        </div>
        <Form.Item
          label="Password"
          name="usrEncryptedPassword"
          rules={[
            {
              required: true,
              message: "Please input your password",
            },
          ]}
        >
          <Input.Password
            onChange={(e) => setpassword(e.target.value)}
            className="input"
            type="password"
          />
        </Form.Item>

        <Form.Item
          // name="confirmPassword"
          label="Confirm Password"
          rules={[
            {
              required: true,
              message: "Please add confirm password",
            },
          ]}
        >
          <Input.Password
            className="input"
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </Form.Item>

        <span className="paragraph_3 !text-[#616161]">
          Password must contain:
        </span>
        <ReactPasswordChecklist
          className="text-[green] !text-[16px]"
          rules={["minLength", "specialChar", "number", "capital", "match"]}
          minLength={8}
          value={password}
          valueAgain={confirmPassword}
          onChange={(isValid) => {
            setisValid(isValid);
          }}
          messages={{
            minLength: "A minimum of 8 characters",
            specialChar: "At least one symbol (~!@$&)",
            number: "At least one number (0-9)",
            capital: "At least one upper case letter (A-Z)",
            match: "Confirm password to match with the password",
          }}
          iconComponents={{
            ValidIcon: (
              <CheckCircleOutlined className="text-[1rem] mr-[.5rem] text-[green]" />
            ),
            InvalidIcon: (
              <img className="mr-[.5rem]" src={checkBox} alt="checkBox" />
            ),
          }}
        />

        <div className="pt-[1.44rem] pb-[2.44rem] w-full flex justify-center items-center">
          <div className="w-[10.625rem] flex flex-col gap-[.5rem]">
            <button
              disabled={!isValid || saving}
              type="submit"
              className={`cstm-btn-2 ${
                !isValid ? "cursor-not-allowed" : "cursor-pointer"
              }`}
            >
              {saving ? <Spin /> : "Next"}
            </button>

            <button
              disabled={saving}
              onClick={handlePrev}
              type="button"
              className="cstm-btn"
            >
              Previous
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
