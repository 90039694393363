import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Form, Input, Spin, Steps } from "antd";
import {
  handleAuthData,
  logout,
  register,
  save,
} from "../../features/auth/authSlice";
import { useEffect, useRef, useState } from "react"; 
import Header from "./Header";
import dayjs from "dayjs"; 
import PasswordStep from "./registration_steps/PasswordStep";
import OrganizationDetailsStep from "./registration_steps/OrganizationDetailsStep";
import {
  cleanOrgObj,
  setOrgDocObj,
  setOrgObj,
} from "../../features/obj/objSlice";
import toast from "react-hot-toast";
import RightSide from "./RightSide";
import AddressDetailsStep from "./registration_steps/AddressDetailsStep";
import { normalizeDateToLocal } from "../../utils";

export default function Register() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { authData } = useSelector((state) => state.auth);
  const { orgObj } = useSelector((state) => state.obj);

  console.log("orgObj", orgObj);
  const formRef = useRef(null);

  const [current, setcurrent] = useState(0);

  const onFinish = async () => {
    const normalizedOrgObj = {
        ...orgObj,
        orgRegistrationDate: normalizeDateToLocal(orgObj?.orgRegistrationDate),
        usrDob: normalizeDateToLocal(orgObj?.usrDob),
    };

    const res = await dispatch(
      save({
        ...normalizedOrgObj,
        // ...orgObj,
        url: "api/v1/public/create-organisation",
      })
    );
    if (res?.payload?.success) {
      await toast.success(res?.payload?.messages?.message);
      await dispatch(cleanOrgObj());
      // await dispatch(setOrgDocObj(res?.payload?.data?.result))
      await form.resetFields();
      await navigate("/registration-success");
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  };

  function handleNext() {
    setcurrent(current + 1);
  }

  function handleBack() {
    setcurrent(current - 1);
  }

  const steps = [
    {
      key: 0,
      title: "Organization Details",
      content: <OrganizationDetailsStep handleNext={handleNext} form={form} />,
    },

    {
      key: 1,
      title: "Address Information",
      content: (
        <AddressDetailsStep
          handleBack={handleBack}
          handleNext={handleNext}
          form={form}
        />
      ),
    },

    {
      key: 2,
      title: "Admin Information",
      content: <PasswordStep handleBack={handleBack} form={form} />,
    },
  ];

  const items = steps.map((item) => ({
    key: item.key,
    title: item.title,
    icon: item.icon,
  }));

  useEffect(() => {
    form.setFieldsValue({
      ...orgObj,
    });
  }, [orgObj, form]);

  const handleSignin = () => {
    navigate("/login");
  };

  return (
    <>
      <div className="flex  w-full  min-h-screen bg-white">
        <div className="flex flex-col w-full ">
          <Header />
          <div className="flex flex-col h-auto w-full justify-center items-center mt-[2.31rem]">
            <span className="heading_1">Register</span>
            <span className="paragraph_1 mt-[1.13rem]">
              Fill in the details below to create an account
            </span>

            <div className="w-full h-full mt-0 lg:mt-[2.44rem] lg:px-[5rem] px-3">
              <Form
                onValuesChange={(data) => {
                  dispatch(setOrgObj({ ...orgObj, ...data }));
                }}
                layout="vertical"
                ref={formRef}
                name="registration"
                onFinish={onFinish}
                scrollToFirstError
                form={form}
                initialValues={orgObj}
                className=""
              >
                <div className="hidden lg:flex">
                  <Steps
                    className=""
                    current={current}
                    labelPlacement="vertical"
                    items={items}
                  />
                </div>

                <div className="mt-[2.12rem]">{steps[current].content}</div>
                <div className="w-full justify-center flex  mb-5">
                  <span className="label_2 !text-[#0A0A0A]">
                    Already have an account ?
                  </span>
                  <button
                    type="button"
                    className=" label_2 !text-orangeYellows ml-[.1rem]"
                    onClick={handleSignin}
                  >
                    Sign In
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>

        <RightSide />
      </div>
    </>
  );
}
