import { Modal } from "antd";

export default function RequestSentModal({ open, handleOkay, header, text }) {
  return (
    <>
      <Modal
        centered
        className="modal-btn mod-height mod-footer"
        title={"Request sent"}
        open={open}
        width={500}
        footer={false}
      >
        <div className="px-[2.94rem] pt-[5rem] w-full h-full">
          <div className="w-full flex flex-col justify-center items-center">
            <div className="flex items-center justify-center rounded-full bg-[#009312] w-[2.5rem] h-[2.5rem] p-[.5rem]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <path
                  d="M20.5 6L9.5 17L4.5 12"
                  stroke="white"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>

            <span className="text-center label_1 mt-[1.25rem]">{header}</span>
            <span className="text-center paragraph_2 my-[1rem] w-[25rem]">
              {text}
            </span>

            <div className="flex items-center justify-center my-[3rem] w-full">
              <div className="w-[12.4375rem] flex items-center gap-[.5rem]">
                <button
                  onClick={handleOkay}
                  className="cstm-btn-2"
                  type="button"
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
