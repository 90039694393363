import { Form, Modal, Spin } from "antd";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextArea from "antd/es/input/TextArea";
import { save } from "../../../features/save/saveSlice";
import toast from "react-hot-toast";

export default function DeclineApprovalModal({
  open,
  handleCancel,
  handleFetch,
  saveObj,
  message,
  header,
  text,
}) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const formRef = useRef(null);

  const { saving } = useSelector((state) => state.save);

  function handleClose() {
    form.resetFields();
    handleCancel();
  }
 
  async function onFinish(data) { 
    data.procAprvStatus = "REJECTED"
    data.procAprvId = saveObj?.procAprvId
    const res = await dispatch(save({
      ...data,
      url:`api/v1/approvals/update-process-approval`
    }));

    if (res?.payload?.success) {
      await toast.success(res?.payload?.messages?.message)
      await handleFetch()
      await handleClose()
    } else {
      toast.error(res?.payload?.messages?.message)
    }
  }

  return (
    <>
      <Modal
        centered
        className="modal-btn mod-height mod-footer"
        title={header}
        open={open}
        onCancel={handleClose}
        width={500}
        footer={false}
      >
 

        <div className="px-[2.94rem]">
          <div className="w-full flex flex-col  items-center">
            <span className="label_2 my-[.19rem]">{text}</span>

            <Form
              layout="vertical"
              ref={formRef}
              name="newUserInvite"
              onFinish={onFinish}
              style={{
                maxWidth: "100%",
                width: "100%",
              }}
              form={form}
            >
              <Form.Item
                label="Reason"
                name="procAprvRemarks"
                rules={[
                  {
                    required: true,
                    message: "Decline reason is required",
                  },
                ]}
              >
                <TextArea
                  rows={5}
                  className="input-textarea"
                />
              </Form.Item>

              <div className="flex items-center justify-end mt-[5rem] w-full">
                <div className="w-[12.4375rem] flex items-center gap-[.5rem]">
                  <button
                    disabled={saving}
                    onClick={handleClose}
                    className="modal_btn_light"
                    type="button"
                  >
                    Cancel
                  </button>

                  <button
                    disabled={saving}
                    className="modal_btn_dark"
                    type="submit"
                  >
                    {saving ? <Spin /> : "Submit"}
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
}
