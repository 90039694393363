import React, { useEffect, useRef, useState } from "react";
import { Form, Input, InputNumber, Modal, Select, Spin, Tag } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import PhoneInput from "react-phone-input-2";
import { paymentMethodsData } from "../../../../data";
import { save } from "../../../../features/save/saveSlice";
import { fetchBanks } from "../../../../features/fetch/fetchSlice";

const AddGroupContactModal = ({ open, handleCancel, handleFetchData,prodd }) => {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { banks, dropLoading } = useSelector((state) => state.fetch);
  const { saving } = useSelector((state) => state.save); 
  const { activeObj } = useSelector((state) => state.obj);

  const { user } = useSelector((state) => state.auth);
  const [bankName, setBankName] = useState();
  const [bankCode, setBankCode] = useState();
  const [accntNumber, setAccntNumber] = useState();

  const [show, setShow] = useState(prodd?.pgdPaymentMode);

 

  async function handleValidValidate() {
    if (!bankCode) {
      toast.error("Please select a bank");
      return;
    }
    if (!accntNumber) {
      toast.error("Please provide a bank number");
      return;
    }
    if (bankCode && accntNumber) {
      const res = await dispatch(
        save({
          url: "api/v1/payments/validate-account",
          bankCode: bankCode,
          beneficiaryAccount: accntNumber,
        })
      );
      if (res?.payload?.data?.result?.status === "SUCCESS") {
        form.setFieldsValue({
          blkBeneficiaryDetails: res?.payload?.data?.result?.benName,
        });
      } else {
        await form.setFieldsValue({
          blkBeneficiaryDetails: null,
        });
        await toast.error("Account not found type the account name");
      }
    }
  }

  const onFinish = async (data) => {
    if (!activeObj?.pgId) {
      toast.error("A group must be selected before creating a list");
      await navigate(`/groups`);
    }
    data.pgdId = prodd?.pgdId ?  prodd?.pgdId :  null
     data.pgdPgId = activeObj?.pgId;
    const res = await dispatch(
      save({
        ...data,
        url: "api/v1/payments/save-payment-group-detail",
      })
    );
    if (res?.payload?.success) {
      toast.success(res?.payload?.messages?.message);
      await form.resetFields();
      await handleFetchData();
      await handleCancel();
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  };

  async function fetchBanksData() {
    await dispatch(
      fetchBanks({
        url: "api/v1/payments/fetch-banks",
      })
    );
  }
 
  useEffect(() => { 
    if (prodd) {  
      form.setFieldsValue(prodd);  
    } else {  
      form.resetFields();  
    }
  }, [prodd]);

  useEffect(()=>{
    setShow(prodd?.pgdPaymentMode)
  },[prodd?.pgdPaymentMode])

  return (
    <>
      <Modal
        className="modal-btn mod-height mod-footer"
        title="Add Group Members"
        open={open}
        onOk={onFinish}
        onCancel={handleCancel}
        width={850}
      >
        <Form
          layout="vertical"
          ref={formRef}
          name="control-ref"
          onFinish={onFinish}
          className=" "
          style={{
            maxWidth: "100%",
          }}
          form={form} 
        >
          <div className="grid grid-cols-2 gap-x-5">
          <Form.Item
            name="pgdPaymentMode"
            className=""
            label={<span>Payment Method</span>}
            rules={[
              {
                required: true,
                message: "Please select payment method",
              },
            ]}
          >
            <Select
              className=""
              allowClear
              style={{
                width: "100%",
              }}
              options={
                paymentMethodsData?.length > 0 &&
                paymentMethodsData?.map((item) => ({
                  value: item?.value,
                  label: item?.label,
                }))
              }
              onChange={(value) => {
                setShow(value);
              }}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label?.toLocaleLowerCase() ?? "").includes(
                  input?.toLocaleLowerCase()
                )
              }
              onDropdownVisibleChange={() => {}}
            />
          </Form.Item>
          <Form.Item
            name="pgdFirstName"
            label="First Name"
            rules={[
              {
                required: true,
                message: "First name is required",
              },
            ]}
          >
            <Input className="input" />
          </Form.Item>
          <Form.Item
            name="pgdLastName"
            label="Last Name"
            rules={[
              {
                required: true,
                message: "Last name is required",
              },
            ]}
          >
            <Input className="input" />
          </Form.Item>
          <Form.Item
            name="pgdNationalId"
            label="National ID"
            rules={[
              {
                required: false,
                message: "Recepient National ID is required",
              },
              {
                validator(_, value) {
                  if (value && value.toString().length < 6) {
                    return Promise.reject(
                      "National ID must be at least 6 characters long"
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <InputNumber
              min={0}
              parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
              className="input flex"
            />
          </Form.Item>

          <Form.Item
            name="pgdAmount"
            label="Amount"
            rules={[
              {
                required: true,
                message: "Enter amount",
              },
            ]}
          >
            <InputNumber
              min={0}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
              placeholder="Enter amount"
              className="input flex"
            />
          </Form.Item>
          </div>
          {show && (
            <>
              {show === "MPESA" ? (
                <Form.Item
                  className="table-form-item"
                  name="pgdReceiverMobileNo"
                  label="Receiver Number"
                  rules={[
                    {
                      required: true,
                      message: "Please add Receiver Number",
                    },
                    {
                      validator: (_, value) => {
                        if (value && value.length === 12) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "Phone number must be exactly 10 characters"
                          )
                        );
                      },
                    },
                  ]}
                >
                  <PhoneInput
                    country="ke"
                    countryCodeEditable={false}
                    className="input"
                  />
                </Form.Item>
              ) : show === "BANK" ? (
                <>
                <div className="flex items-center lg:gap-x-5">
                  <Form.Item
                    name="pgdBnkUid"
                    className="w-full"
                    label={<span>Bank</span>}
                    rules={[
                      {
                        required: true,
                        message: "Please select Bank",
                      },
                    ]}
                  >
                    <Select
                      className=""
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      options={
                        banks?.length > 0 &&
                        banks?.map((item) => ({
                          value: item?.bnkUid,
                          label: item?.bnkName,
                        }))
                      }
                      onChange={(value) => {
                        const selectedItem = banks?.find(
                          (item) => item?.bnkUid === value
                        );
                        setBankName(selectedItem?.bnkName || "");
                        setBankCode(selectedItem?.bnkNo || "");
                      }}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label?.toLocaleLowerCase() ?? "").includes(
                          input?.toLocaleLowerCase()
                        )
                      }
                      onDropdownVisibleChange={() => {
                        fetchBanksData();
                      }}
                      dropdownRender={(menu) => (
                        <>
                          {dropLoading && (
                            <div
                              style={{
                                padding: "8px",
                                textAlign: "center",
                              }}
                            >
                              <Spin className="sms-spin" />
                            </div>
                          )}
                          {menu}
                        </>
                      )}
                    />
                  </Form.Item>

                  <Form.Item
                    className="w-full"
                    name="pgdBeneficiaryAccount"
                    label="Account Number"
                    rules={[
                      {
                        required: true,
                        message: "Enter Account Number",
                      },
                    ]}
                  >
                    <Input
                      onChange={(e) => {
                        setAccntNumber(e.target.value);
                      }}
                      className="input"
                    />
                  </Form.Item>

                  <div className="w-[100px]">
                    <button
                      type="button"
                      className="cstm-btn-2 !rounded-[10px]"
                      disabled={saving}
                      onClick={handleValidValidate}
                    >
                      {saving ? <Spin /> : "Validate"}
                    </button>
                  </div>
                </div>
                <Form.Item
                name="blkBeneficiaryDetails"
                label="Account Name"
                rules={[
                    {
                        required: true,
                        message: 'Enter Account Name',
                    },
                ]}
            >
                <Input disabled={saving} className="input" />
            </Form.Item>
            </>
              ) : null}
            </>
          )}

          <div className="flex justify-between mt-10">
            <div className="justify-start"></div>
            <div className="justify-end flex items-center gap-x-5">
              <div className="w-[200px] ">
                <button
                  key="back"
                  type="button"
                  onClick={handleCancel}
                  className="cstm-btn"
                >
                  Cancel
                </button>
              </div>

              <div className="w-[200px]">
                <button
                  key="submit"
                  type="submit"
                  className="cstm-btn-2"
                  disabled={saving}
                >
                  {saving ? <Spin /> : "Save"}
                </button>
              </div>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};
export default AddGroupContactModal;
