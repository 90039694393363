import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import citiesData from "../../countries.json";
import { addressTypeData } from "../../data";
import { useNavigate } from "react-router-dom";
import { Form, Input, Select, Spin } from "antd";

function Address({addressData}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const formRef = useRef(null);

  const { saving } = useSelector((state) => state.save);
  const { orgObj } = useSelector((state) => state.obj);

  const onSearchCountry = (value) => {};
  const filterOptionCountry = (input, option) =>
    (option?.children?.toLowerCase() ?? "").includes(input.toLowerCase());

  const country = citiesData?.find((city) => city.name === orgObj?.orgCountry);
  const [city, setcity] = useState(
    orgObj?.orgCountry ? country?.cities?.map((city) => city?.name) : []
  );

  function selectedCountry(value) {
    const country = citiesData?.find((city) => city.name === value);
    if (country && country?.cities && country?.cities?.length > 0) {
      const cityNames = country?.cities?.map((city) => city?.name);
      setcity(cityNames);
    }
  }

  const handleBack = async () => {};

  const onFinish = () => {};
  console.log('addressData',addressData)
  return (
    <div>
      <Form
        layout="vertical"
        ref={formRef}
        name="newUserInvite"
        onFinish={onFinish}
        style={{
          maxWidth: "100%",
          width: "100%",
        }}
        form={form}
        initialValues={addressData}
      >
        {" "}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
          <Form.Item
            name="orgAddressType"
            className=""
            label={"Address Type"}
            rules={[
              {
                required: true,
                message: "Please select Address Types",
              },
            ]}
          >
            <Select
              className=""
              allowClear
              style={{
                width: "100%",
              }}
              options={
                addressTypeData?.length > 0 &&
                addressTypeData?.map((item) => ({
                  value: item?.value,
                  label: item?.label,
                }))
              }
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label?.toLocaleLowerCase() ?? "").includes(
                  input?.toLocaleLowerCase()
                )
              }
            />
          </Form.Item>

          <Form.Item
            label="Line 1"
            name="orgLine1"
            rules={[
              {
                required: true,
                message: "Line 1 is required",
              },
              {
                min: 3,
                max: 150,
                message: "Postal code must be >=3 and <=150 characters",
              },
            ]}
          >
            <Input className="input" />
          </Form.Item>
          <Form.Item
            label="Line 2"
            name="orgLine2"
            rules={[
              {
                required: false,
                message: "Line 2 is required",
              },
              {
                min: 3,
                max: 150,
                message: "Postal code must be >=3 and <=150 characters",
              },
            ]}
          >
            <Input className="input" />
          </Form.Item>
          <Form.Item
            label="Line 3"
            name="orgLine3"
            rules={[
              {
                required: false,
                message: "Line 3 is required",
              },
              {
                min: 3,
                max: 150,
                message: "Postal code must be >=3 and <=150 characters",
              },
            ]}
          >
            <Input className="input" />
          </Form.Item>

          <Form.Item
            label="Postal code"
            name="orgPostalCode"
            rules={[
              {
                required: false,
                message: "Postal code is required",
              },
              {
                min: 3,
                max: 10,
                message: "Postal code must be >=3 and <=10 characters",
              },
            ]}
          >
            <Input className="input" />
          </Form.Item>

          <Form.Item
            name="orgCountry"
            label="Country"
            rules={[
              {
                required: true,
                message: "Please select a country",
              },
            ]}
          >
            <Select
              showSearch
              style={{
                height: "3.25rem",
              }}
              className="reg-input"
              placeholder="Country"
              onChange={async (value) => {
                await selectedCountry(value);
                await form.setFieldsValue({ orgCity: undefined });
              }}
              size="large"
              optionFilterProp="children"
              onSearch={onSearchCountry}
              filterOption={filterOptionCountry}
            >
              {citiesData?.map((city) => (
                <Select.Option key={city?.name} value={city?.name}>
                  {city?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="City"
            name="orgCity"
            rules={[
              {
                required: true,
                message: "Please select a city",
              },
            ]}
          >
            <Select
              showSearch
              style={{
                height: "3.25rem",
              }}
              disabled={city?.length ? false : true}
              placeholder={"City"}
              size="large"
              className="reg-input"
              optionFilterProp="children"
              onSearch={onSearchCountry}
              filterOption={filterOptionCountry}
            >
              {city?.map((city) => {
                return (
                  <Select.Option required key={city} value={city}>
                    {city}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            label="State"
            name="orgState"
            rules={[
              {
                required: true,
                message: "State is required",
              },
              {
                min: 3,
                max: 50,
                message: "Postal code must be >=3 and <=50 characters",
              },
            ]}
          >
            <Input className="input" />
          </Form.Item>
        </div>
        <div className="flex items-center justify-end mt-[5rem] w-full">
          <div className="w-[12.4375rem] flex items-center gap-[.5rem]">
            <button
              disabled={saving}
              onClick={handleBack}
              className="modal_btn_light"
              type="button"
            >
              Cancel
            </button>

            <button disabled={saving} className="modal_btn_dark" type="submit">
              {saving ? <Spin /> : "Update"}
            </button>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default Address;
