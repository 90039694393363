import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashCard from "./DashCard";
import DashLineChart from "./DashLineChart";
import DashRecentTransactions from "./DashRecentTransactions";
import { fetchDashboard } from "../../features/fetch/fetchSlice";

function DashboardList() {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth);
  const { dashboardData } = useSelector((state) => state.fetch);
 
  const paySummary = [];
  const colReceipts = [];

  async function fetchDashboardData(){
    await dispatch(fetchDashboard({
      orgUid:user?.usrOrgId,
      url:'api/v2/dashboard/fetch-dashboard'
    }))
  }
  useEffect(()=>{
    fetchDashboardData()
  },[])
  
  return (
    <div className=" px-3  w-full h-full overflow-y-scroll">
      <div className="mb-10 ml-10 lg:ml-0 ">
        <h3 className="text-[24px] font-[600] text-[#000] mb-[1rem]">
          {user?.orgName}
        </h3>

        <h1 className="text-[20px] font-[500] text-[#0A0A0A]">
          Welcome, {user?.usrFirstName}
        </h1>
        <p className="text-[13px] font-[500] text-[#575050]">
          Here's a summary of payment activity
        </p>
      </div>

      <div>
        <DashCard dashboardData={dashboardData}/>
      </div>
      <div>
        <DashLineChart />
      </div>
      <div className="flex lg:flex-row flex-col gap-4 lg:w-[80%] w-full">
        <DashRecentTransactions />
        <DashRecentTransactions disb={true} />
      </div>
    </div>
  );
}

export default DashboardList;
