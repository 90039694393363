import { Spin, Table, Tooltip } from "antd";
import moment from "moment";
import { capitalize, formatMoney, getRandomColor } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { save } from "../../features/save/saveSlice";
import { useEffect, useState } from "react";
import useModalToggle from "../../custom_hooks/useModalToggle";
import info from "../../assets/svg/Info.svg";
import info32 from "../../assets/svg/Info32.svg";
import AllPurposeOtpModal from "./modal/AllPurposeOtpModal";
import RequestSentModal from "./modal/RequestSentModal";
import TableLoading from "../../components/TableLoading";
import DeclineApprovalModal from "./modal/DeclineApprovalModal";
import FundsTransferInfoModal from "./modal/FundsTransferInfoModal";
import BreadCrumb from "../../layout/BreadCrumb";
import { fetchApprovalInfo, fetchProcessApprs } from "../../features/fetch/fetchSlice";
import toast from "react-hot-toast";

export default function TransferApproval() {
  const { open, handleCancel, handleOpen } = useModalToggle();
  const veriyModal = useModalToggle();
  const success = useModalToggle();
  const moreInfo = useModalToggle();

  const dispatch = useDispatch();

  const { processApprs, isLoading } = useSelector((state) => state.fetch);
  const { saving } = useSelector((state) => state.save);
  const { user } = useSelector((state) => state.auth);
  const [savingProcAprvId, setSavingProcAprvId] = useState(null);
  const [prodd, setProdd] = useState();

  const [saveObj, setsaveObj] = useState({});

 async function handleInfo(item) {
   await setsaveObj(item); 
   await dispatch(fetchApprovalInfo({
        url:'api/v1/approvals/get-process-approval-doc-info',
        procAprvId:item?.procAprvId
      })) 
     await moreInfo.handleOpen();
  }

  async function handleClose() {
    await setsaveObj({});
    await veriyModal.handleCancel();
    await moreInfo.handleCancel();
    await handleCancel();
  }

  async function handleInitiateApproval(item) {
    setSavingProcAprvId(item?.procAprvId);
    const res = await dispatch(
      save({
        procAprvId: item?.procAprvId ?? prodd?.procAprvId,
        url: `api/v1/approvals/send-process-approval-otp`,
      })
    );
    if (res?.payload?.success) {
      veriyModal.handleOpen();
      toast.success(res?.payload?.messages?.message);
    } else {
      toast.error(res?.payload?.messages?.message);
    }
    setSavingProcAprvId(null);
  }

  async function handleDecline(item) {
    // setsaveObj({
    //     ...item,
    //     data: {
    //         apvId: item?.apvId,
    //         apvAmount: item?.apvAmount,
    //         apvType: 'SETTLEMENT',
    //         apvRefId: item?.apvRefId,
    //         apvStatus: 'REJECT',
    //         saveUrl: '/api/v1/approval/approve',
    //         url: applicationUrl().url,
    //     },
    // });
    handleOpen();
  }
  
  async function handleAccept(otp) {
    const res = await dispatch(save({
      usrSecret:otp,
      procAprvId:prodd?.procAprvId,
      url:`api/v1/approvals/verify-process-approval-otp`
    }));
    if (res?.payload?.success) {
        await veriyModal.handleCancel();
        await success.handleOpen();
    } else {
       toast.error(res?.payload?.messages?.message)
    }
  }

  async function handleOkay() {
    await handleFetchSettlementApprovals();
    await success.handleCancel();
    await setsaveObj({});
    await setProdd('')
  }

  async function handleFetchSettlementApprovals() {
    await dispatch(
      fetchProcessApprs({
        procAprvName: "FUNDS_TRANSFER",
        procAprvStatus: "PENDING",
        url: `api/v1/approvals/fetch-process-approvals`,
      })
    );
  }

  useEffect(() => {
    handleFetchSettlementApprovals();
  }, []);

  if (isLoading) return <TableLoading />;

  const columns = [
    {
      title: "Date",
      dataIndex: "procAprvCreatedTime",
      render: (item) => (
        <span>{moment(item).format("Do MMM YYYY, h:mm:ss A")}</span>
      ),
      sorter: (a, b) =>
        new Date(a.procAprvCreatedTime) - new Date(b.procAprvCreatedTime),
    },
    {
      title: "Initiator",
      dataIndex: "procAprvIntiatorUsrName",
      render: (item) => (
        <>
          <div className="w-full flex items-center gap-[.5rem]">
            <img
              src={`https://ui-avatars.com/api/?name=${encodeURIComponent(
                item
              )}&background=${getRandomColor()}&color=fff`}
              className="w-[2.1875rem] h-[2.1875rem] rounded-full object-cover"
              alt="avatar"
            />
            <span>{item}</span>
          </div>
        </>
      ),
    },
    {
      title: "Amount (KES)",
      dataIndex: "procAprvAmount",
      render: (item) => <span>{formatMoney(item, "KES")}</span>,
    },
    {
      title: "More info",
      render: (item) => (
        <button onClick={() => handleInfo(item)} type="button">
          <img src={info32} alt="info" />
        </button>
      ),
    },
    {
      title: "Action",
      render: (item) => {
        if (item?.createdById === user?.jti) {
          return (
            <div className="w-full flex items-center gap-[.5rem]">
              <Tooltip
                arrow={false}
                // open={true}
                placement="bottom"
                title={
                  <>
                    <div className="w-full flex flex-col p-[1rem] gap-[.5rem]">
                      <div className="flex items-start gap-[.5rem]">
                        <img src={info32} alt="info" />
                        <span className="toolTip_header">
                          Waiting for approval
                        </span>
                      </div>

                      <span className="toolTip_text">
                        Waiting for other group officials to approve.
                      </span>
                    </div>
                  </>
                }
              >
                <img src={info} alt="info" />
              </Tooltip>
              <span>Pending approval</span>
            </div>
          );
        }
        return (
          <div
            className="flex items-center gap-[.75rem]"
            onClick={() => setProdd(item)}
          >
            <button
              disabled={saving}
              onClick={() => handleDecline(item)}
              className="modal_btn_light"
              type="button"
            >
              Decline
            </button>
            <button
              disabled={saving && savingProcAprvId === item?.procAprvId}
              onClick={() => handleInitiateApproval(item)}
              className="modal_btn_dark"
              type="button"
            >
              {savingProcAprvId === item?.procAprvId && saving ? (
                <Spin />
              ) : (
                "Approve"
              )}
            </button>
          </div>
        );
      },
    },
  ];
  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];
  const breadList = [
    {
      title: (
        <div className="flex items-center gap-[.25rem]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <g clipPath="url(#clip0_627_13942)">
              <path
                d="M13.7899 6.89067L7.75241 0.856289L7.34772 0.451602C7.25551 0.360003 7.13082 0.308594 7.00085 0.308594C6.87087 0.308594 6.74618 0.360003 6.65397 0.451602L0.211783 6.89067C0.117301 6.98478 0.0426296 7.09687 -0.00782254 7.22031C-0.0582747 7.34376 -0.0834854 7.47607 -0.0819665 7.60942C-0.0757165 8.15942 0.382096 8.59848 0.932096 8.59848H1.59616V13.6875H12.4055V8.59848H13.0837C13.3508 8.59848 13.6024 8.49379 13.7915 8.30473C13.8846 8.21193 13.9583 8.10159 14.0085 7.98009C14.0586 7.8586 14.0842 7.72836 14.0837 7.59692C14.0837 7.33129 13.979 7.07973 13.7899 6.89067ZM7.87585 12.5625H6.12585V9.37504H7.87585V12.5625ZM11.2805 7.47348V12.5625H8.87585V9.00004C8.87585 8.65473 8.59616 8.37504 8.25085 8.37504H5.75085C5.40553 8.37504 5.12585 8.65473 5.12585 9.00004V12.5625H2.72116V7.47348H1.22116L7.00241 1.69691L7.36335 2.05785L12.7821 7.47348H11.2805Z"
                fill="black"
                fillOpacity="0.45"
              />
            </g>
            <defs>
              <clipPath id="clip0_627_13942">
                <rect width="14" height="14" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span>Home</span>
        </div>
      ),
      href: "/#/home",
    },
    {
      title: capitalize("Funds Transfer Approvals"),
    },
  ];
  return (
    <div className="w-full h-full overflow-y-scroll">
      <div className="justify-between items-center white_card">
        <BreadCrumb
          breadList={breadList}
          header={"Funds Transfer Approval"}
          info={"Here’s an overview of your Funds Transfer Approvals"}
        />
      </div>

      <div className="white_card mt-[1.25rem]">
        <Table
          scroll={{
            x: 1000,
          }}
          pagination={{
            defaultPageSize: 7,
            hideOnSinglePage: true,
            pageSizeOptions: [10, 20, 50, 100],
          }}
          rowKey="procAprvId"
          className="mt-[1.5rem] !w-full"
          columns={columns}
          dataSource={processApprs}
        />
      </div>

      <DeclineApprovalModal
        open={open}
        handleFetch={handleFetchSettlementApprovals}
        handleCancel={handleClose}
        text={`You are rejecting ${prodd?.procAprvDocName}’s funds transfer request. Give them a reason below.`}
        header={"Reject Settlement"}
        saveObj={prodd}
        message={"apvDeclineReason"}
      />

      <AllPurposeOtpModal
        open={veriyModal.open}
        handleNext={handleAccept}
        handleCancel={handleClose}
        handleInitiateApproval={handleInitiateApproval}
      />

      <RequestSentModal
        open={success.open}
        handleOkay={handleOkay}
        header={"Settlement request approved"}
        text={`You have approved ${
          prodd?.procAprvIntiatorUsrName
        }’s request to make account settlement of KES ${formatMoney(
          prodd?.procAprvAmount,
          "KES"
        )}`}
      />

      <FundsTransferInfoModal
        open={moreInfo.open}
        handleOkay={handleClose}
        selectedItem={saveObj}
      />
    </div>
  );
}
