import React, { useEffect, useRef, useState } from "react"; 
import { Form, Spin, Tree } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"; 
import GenerateModal from "./GenerateModal";
import { fetchReportTree } from "../../features/fetch/fetchSlice";
import BreadCrumb from "../../layout/BreadCrumb";
 

function MainReports() {
  const handleOk = () => {
    setExpandedKeys([]);
    setTreeData([
      { title: "Tree Node", key: "0", isLeaf: false, disableCheckbox: true },
    ]);
  };
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const formRef = useRef(null); 

  const { user } = useSelector((state) => state.auth);
  const [prodd,setProdd] =  useState('')

  const [open, setopen] = useState(false);

 async function handleCancel() {
   await setopen(false); 
   await setCheckedKeys([])
  }

  const { loading, reportTree } = useSelector((state) => state.fetch);
  const dispatch = useDispatch();

   const [treeData, setTreeData] = useState([
      { title: "NDOVUPAY_REPORTS", key: "category_1", isLeaf: false, disableCheckbox: true },
    ]); 
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);

  const fetchChildren = async (key) => {
    try {
      const res = await dispatch(
        fetchReportTree({ 
          url:'api/v1/reports/fetch-report-tree',
          node: key,
        })
      );
      console.log('res?.payload',res?.payload)
      if (res?.payload) {
        const checkedKeysFromResponse = res?.payload
          .filter((item) => item?.checked)
          .map((item) => item?.id);

        setCheckedKeys(checkedKeysFromResponse);

        return res?.payload?.map((item) => ({
          title: item?.text,
          key: item?.id,
          isLeaf: item?.cls === "file",
          disableCheckbox: item?.cls === "folder",
          cls: item?.cls,
        }));
      }
      return [];
    } catch (error) {
      console.error("Error fetching children: ", error);
      return [];
    }
  };

  const onExpand = async (expandedKeys, { expanded, node }) => {
    if (expanded) {
      setExpandedKeys(expandedKeys);
      if (expandedKeys?.includes("applications") && expandedKeys?.includes("0")) {
        setExpandedKeys((prevKeys) => {
          return ["applications", "0", node.key];
        });
      } else if (
        expandedKeys?.includes("reports") &&
        expandedKeys?.includes("0")
      ) {
        setExpandedKeys((prevKeys) => {
          return ["reports", "0", node.key];
        });
      }

      const children = await fetchChildren(node?.key);
      setTreeData((prevTreeData) =>
        updateTreeData(prevTreeData, node?.key, children)
      );
    } else {
      setExpandedKeys(expandedKeys);
    }
  };

  const updateTreeData = (list, key, children) => {
    return list.map((node) => {
      if (node?.key === key) {
        return { ...node, children };
      }
      if (node?.children) {
        return {
          ...node,
          children: updateTreeData(node?.children, key, children),
        };
      }
      return node;
    });
  };

  const onCheck = async (checkedKeysValue, { checked, node }) => {
    const singleCheckedKey = checked ? [node?.key] : [];
    await setCheckedKeys(singleCheckedKey);  
    await setProdd(node);  
    await setopen(checked);   
  };

  const onSelect = async (selectedKeys, { selected, node }) => {
    const singleCheckedKey = selected ? [node?.key] : [];
    await setCheckedKeys(singleCheckedKey);
    await setProdd(node);
    await setopen(selected);
  };

  const breadList = [
    {
      title: (
        <div className="flex items-center gap-[.25rem]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <g clipPath="url(#clip0_627_13942)">
              <path
                d="M13.7899 6.89067L7.75241 0.856289L7.34772 0.451602C7.25551 0.360003 7.13082 0.308594 7.00085 0.308594C6.87087 0.308594 6.74618 0.360003 6.65397 0.451602L0.211783 6.89067C0.117301 6.98478 0.0426296 7.09687 -0.00782254 7.22031C-0.0582747 7.34376 -0.0834854 7.47607 -0.0819665 7.60942C-0.0757165 8.15942 0.382096 8.59848 0.932096 8.59848H1.59616V13.6875H12.4055V8.59848H13.0837C13.3508 8.59848 13.6024 8.49379 13.7915 8.30473C13.8846 8.21193 13.9583 8.10159 14.0085 7.98009C14.0586 7.8586 14.0842 7.72836 14.0837 7.59692C14.0837 7.33129 13.979 7.07973 13.7899 6.89067ZM7.87585 12.5625H6.12585V9.37504H7.87585V12.5625ZM11.2805 7.47348V12.5625H8.87585V9.00004C8.87585 8.65473 8.59616 8.37504 8.25085 8.37504H5.75085C5.40553 8.37504 5.12585 8.65473 5.12585 9.00004V12.5625H2.72116V7.47348H1.22116L7.00241 1.69691L7.36335 2.05785L12.7821 7.47348H11.2805Z"
                fill="black"
                fillOpacity="0.45"
              />
            </g>
            <defs>
              <clipPath id="clip0_627_13942">
                <rect width="14" height="14" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span>Home</span>
        </div>
      ),
      href: "/#/home",
    },
    {
      title: <div className="text-lighGold text-[16px]">Main Reports</div>,
    },
  ];
  return (
    <div className="w-full h-full overflow-y-scroll">
     <div className="justify-between items-center white_card">
        <BreadCrumb
          breadList={breadList}
          header={"Reports Information"}
          info={"View all reports here"}
          btn={false} 
        />
      </div>

      <div className="bg-white mt-[1.25rem] rounded-[10px] min-h-[100vh] p-10">
        <Spin spinning={loading}>
          <Tree
            checkable
            onExpand={onExpand}
            expandedKeys={expandedKeys}
            checkedKeys={checkedKeys}
            onCheck={onCheck}
            onSelect={onSelect} 
            treeData={treeData} 
          />
        </Spin> 
      </div>

      <GenerateModal 
        open={open}
        handleCancel={handleCancel}
        prodd={prodd}
      />
    </div>
  );
}

export default MainReports;
