import { DatePicker, Form, Input, Modal, Select, Skeleton, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux"; 
import toast from "react-hot-toast";
import { uniqBy } from "lodash";
import { normalizeDateToLocal } from "../../utils";
import { fetchLovData, fetchLovGridData, generateReport } from "../../features/fetch/fetchSlice";

function GenerateModal({ open, handleCancel, prodd }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const formRef = useRef(null);

  const { lovData, loading, loadingGrid, loadingGenerateReport } = useSelector(
    (state) => state.fetch
  );
  const [loadingOptions, setLoadingOptions] = useState({});
  const [selectOptions, setSelectOptions] = useState({});

  useEffect(() => {
    if (prodd?.key) {
      dispatch(fetchLovData({ 
        url:'api/v1/reports/fetch-lov-data',
        reportId: prodd?.key }));
    }
  }, [prodd?.key, dispatch]);

  const fetchSelectOptions = async (paramId) => {
    setLoadingOptions((prev) => ({ ...prev, [paramId]: true }));
    const result = await dispatch(fetchLovGridData({ 
      url:'/api/v1/reports/fetch-lov-data-grid',
      paramId }))?.unwrap();

    const uniqueOptions = uniqBy(result, "v_key_id")?.map((item) => ({
      value: item?.v_key_id,
      label: item?.desc,
    }));

    setSelectOptions((prev) => ({
      ...prev,
      [paramId]: uniqueOptions,
    }));

    setLoadingOptions((prev) => ({ ...prev, [paramId]: false }));
  };

  const onFinish = async (values) => {
    const transformedValues = Object?.fromEntries(
      Object?.entries(values)?.map(([key, value]) => {
        const param = lovData?.find((item) => String(item?.paramId) === key);
        const formattedValue =
          param?.paramType === "DATE" ? normalizeDateToLocal(value) : value;

        return [`rpt_paramId_${key}`, formattedValue ?? null];
      })
    );
    const res = await dispatch(
      generateReport({
        rptId: lovData?.[0]?.paramRprId,
        rptIdHolder1: prodd?.key,
        SIGN: "SPA",
        ...transformedValues,
      })
    );
    if (res?.type === "dropdownSlice/generateReport/fulfilled") {
      const isPDF =
        new TextDecoder().decode(res.payload.slice(0, 4)) === "%PDF";

      const blob = new Blob([res.payload], {
        type: isPDF ? "application/pdf" : "text/csv",
      });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        isPDF ? "generated_report.pdf" : "generated_report.csv"
      );
      document.body.appendChild(link);
      link.click();

      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
      await toast.success("Generated Successfully");
      await handleModalCancel();
    } else {
      toast.error("Error generating Report");
    }
  };
  const handleModalCancel = () => {
    form.resetFields();
    handleCancel();
  };

  return (
    <Modal
      className="modal-btn mod-height mod-footer"
      title={`Generate report for ${prodd?.title}`}
      open={open}
      onOk={form.submit}
      onCancel={handleModalCancel}
      width={850}
    >
      {loading ? (
        <Skeleton />
      ) : ( 
        <Form
          layout="vertical"
          ref={formRef}
          name="dynamic-form"
          onFinish={onFinish}
          form={form}
          style={{ maxWidth: "100%" }}
        >
          {lovData?.map((item) => (
            <div key={item?.paramId}>
              <Form.Item
                key={item?.paramId}
                name={item?.paramId}
                label={item?.paramLabel}
                rules={[
                  {
                    required: item?.paramRequired === "Y" ? true : false,
                    message: `Please select ${item?.paramLabel}`,
                  },
                ]}
              >
                {item?.paramType === "DATE" ? (
                  <DatePicker
                    className="input"
                    placeholder={`Select date`}
                    format="DD-MM-YYYY"
                    style={{ width: "100%" }}
                  />
                ) : item?.paramType === "COMBO" || item?.paramType === "LOV" ? (
                  <Select
                    allowClear
                    style={{ width: "100%" }}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label?.toLowerCase() ?? "").includes(
                        input.toLowerCase()
                      )
                    }
                    onDropdownVisibleChange={(open) => {
                      if (open && !selectOptions[item?.paramId]) {
                        fetchSelectOptions(item?.paramId);
                      }
                    }}
                    options={selectOptions[item?.paramId] || []}
                    dropdownRender={(menu) => (
                      <>
                        {loadingGrid && (
                          <div
                            style={{
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            <Spin className="sms-spin" />
                          </div>
                        )}
                        {menu}
                      </>
                    )}
                  />
                ) : item?.paramType === "TEXT" ? (
                  <Input className="input" />
                ) : null}
              </Form.Item>
            </div>
          ))}
          <div className="flex lg:flex-row flex-col justify-between mt-[56px] mb-[48px]">
            <div className="justify-start"></div>
            <div className="justify-end flex items-center gap-x-2">
              <div className="w-[200px]">
                <button
                  key="back"
                  type="button"
                  onClick={handleModalCancel}
                  className="cstm-btn !bg-white !text-lightBlack !border !border-lightBlack"
                >
                  Cancel
                </button>
              </div>

              <div className="w-[250px]">
                <button
                  key="submit"
                  type="submit"
                  className="cstm-btn"
                  disabled={loadingGenerateReport}
                >
                  {loadingGenerateReport ? <Spin /> : "   Generate Report"}
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Modal>
  );
}

export default GenerateModal;
