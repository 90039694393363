import React, { useEffect, useRef, useState } from "react";
import { Form, Input, InputNumber, Modal, Select, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { save } from "../../../../features/save/saveSlice";
import { paymentMethodsData } from "../../../../data";
import PhoneInput from "react-phone-input-2";

const { TextArea } = Input;
const AddSettlementModal = ({ open, handleCancel, handleFetchData, prodd }) => {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { saving } = useSelector((state) => state.save);
  const { banks } = useSelector((state) => state.fetch);

  const [paymentModeName, setPaymentModeName] = useState(prodd?.settlementType);

  const onFinish = async (data) => {
    data.settlementId = prodd.settlementId ?? null;
    const res = await dispatch(
      save({
        ...data,

        url: "api/v1/wallet/save-settlement",
      })
    );
    if (res?.payload?.success) {
      await toast.success(res?.payload?.messages?.message);
      await handleFetchData();
      await handleCancel();
      await form.resetFields();
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  };

  useEffect(()=>{
    setPaymentModeName(prodd?.settlementType)
  },[prodd])

  useEffect(() => {
    if (prodd) {
      form.setFieldsValue(prodd);
    } else {
      form.resetFields();
    }
  }, [prodd, form]); 

  return (
    <>
      <Modal
        className="modal-btn mod-height mod-footer"
        title={prodd ? `Edit ${prodd?.settlementName}` : "Add Settlement Account"}
        open={open}
        onOk={onFinish}
        onCancel={handleCancel}
        width={850}
      >
        <Form
          layout="vertical"
          ref={formRef}
          name="control-ref"
          onFinish={onFinish}
          className=" "
          style={{
            maxWidth: "100%",
          }}
          form={form}
          // initialValues={prodd}
        >
          <Form.Item
            name="settlementName"
            className=""
            label={<span>Settlement Name</span>}
            rules={[
              {
                required: true,
                message: "Please add settlement name",
              },
            ]}
          >
            <Input className="input" />
          </Form.Item>

          <Form.Item
            name="settlementType"
            className=""
            label={<span>Settlement Type</span>}
            rules={[
              {
                required: true,
                message: "Please select settlement type",
              },
            ]}
          >
            <Select
              className=""
              allowClear
              style={{
                width: "100%",
              }}
              options={
                paymentMethodsData?.length > 0 &&
                paymentMethodsData?.map((item) => ({
                  value: item?.value,
                  label: item?.label,
                }))
              }
              onChange={(value) => { 
                setPaymentModeName(value);
            }}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label?.toLocaleLowerCase() ?? "").includes(
                  input?.toLocaleLowerCase()
                )
              }
              onDropdownVisibleChange={() => {}}
            />
          </Form.Item>
          {
            paymentModeName === "BANK" ? (
              <>
              <Form.Item
              name="settlementAccountNumber"
              label="Account Number"
              rules={[
                {
                  required: true,
                  message: "Enter account number",
                },
              ]}
            >
              <InputNumber
                min={0}
                // formatter={(value) =>
                //   `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                // }
                parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                placeholder="Enter account"
                className="input flex"
              />
            </Form.Item>
  
            <Form.Item
              name="settlementBankName"
              className=""
              label={<span>Bank</span>}
              rules={[
                {
                  required: true,
                  message: "Please select settlement bank",
                },
              ]}
            >
              <Select
                className=""
                allowClear
                style={{
                  width: "100%",
                }}
                options={
                  banks?.length > 0 &&
                  banks?.map((item) => ({
                    value: item?.bnkNo,
                    label: item?.bnkName,
                  }))
                }
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label?.toLocaleLowerCase() ?? "").includes(
                    input?.toLocaleLowerCase()
                  )
                }
                onDropdownVisibleChange={() => {}}
              />
            </Form.Item>
            </>
            ): paymentModeName === "MPESA"?(
              <Form.Item
              className="table-form-item"
              name="settlementPhoneNumber"
              label="Phone Number"
              rules={[
                {
                  required: true,
                  message: "Please add Receiver Number",
                },
                {
                  validator: (_, value) => {
                    if (value && value.length === 12) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Phone number must be exactly 10 characters")
                    );
                  },
                },
              ]}
            >
              <PhoneInput
                country="ke"
                countryCodeEditable={false}
                className="input"
              />
            </Form.Item>
            ):null
          }
          

          <div className="flex lg:flex-row flex-col justify-between mt-[56px] mb-[48px]">
            <div className="justify-start"></div>
            <div className="justify-end flex items-center gap-x-2">
              <div className="w-[200px]">
                <button
                  key="back"
                  type="button"
                  onClick={handleCancel}
                  className="cstm-btn"
                >
                  Cancel
                </button>
              </div>

              <div className="w-[200px]">
                <button
                  key="submit"
                  type="submit"
                  className="cstm-btn-2"
                  disabled={saving}
                >
                  {saving ? <Spin /> : "Create"}
                </button>
              </div>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};
export default AddSettlementModal;
