import React, { useEffect, useRef, useState } from "react";
import { Modal, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";

export default function AllPurposeOtpModal({
  open,
  handleCancel,
  handleNext,
  handleInitiateApproval,
}) {
  const dispatch = useDispatch();

  const { saving } = useSelector((state) => state.save);
  const { authLoading, user } = useSelector((state) => state.auth);

  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

  const [verificationCode, setVerificationCode] = useState(["", "", "", ""]);
  const [counter, setCounter] = useState({ minutes: 0, seconds: 0 });

  const cancatenatedString = verificationCode?.join("");

  const handleInputChange = (e, index) => {
    const { value } = e.target;

    if (value === "") {
      if (index > 0) {
        inputRefs[index - 1].current.focus();
      }
    } else if (index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }

    const updatedVerificationCode = [...verificationCode];
    updatedVerificationCode[index] = value;
    setVerificationCode(updatedVerificationCode);
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !verificationCode[index] && index > 0) {
      inputRefs[index - 1].current.focus();
    }
  };

  const onFinish = async () => {
    await handleNext(cancatenatedString);
    await setVerificationCode(["", "", "", ""]);
  };

  async function handleResendOtp() {
    handleInitiateApproval();
    setCounter({ minutes: 0, seconds: 59 })
  }

  async function handleClose() {
    await handleCancel();
    await setVerificationCode(["", "", "", ""]);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (counter.minutes === 0 && counter.seconds === 0) {
        clearInterval(interval);
      } else {
        setCounter((prevCounter) => {
          if (prevCounter.seconds === 0) {
            return { minutes: prevCounter.minutes - 1, seconds: 59 };
          } else {
            return { ...prevCounter, seconds: prevCounter.seconds - 1 };
          }
        });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [counter, authLoading]);

  return (
    <>
      <Modal
        afterOpenChange={(isOpen) =>
          isOpen
            ? setCounter({ minutes: 0, seconds: 59 })
            : setCounter({ minutes: 0, seconds: 0 })
        }
        centered
        className="modal-btn mod-height mod-footer"
        title="OTP Verification"
        open={open}
        footer={false}
        onCancel={handleClose}
        width={500}
      >
        <div className="px-[2.94rem] pt-[5rem]">
          <div className="w-full flex flex-col justify-center items-center">
            <span className="heading_5">Let’s verify your account</span>
            <span className="paragraph_2 mt-[1.25rem]">
              Enter the 4 digit code sent to your email
            </span>

            <div
              style={{
                maxWidth: "100%",
                marginTop: "3.75rem",
              }}
            >
              <div className="flex flex-col">
                <div className="flex items-center gap-[1.25rem]">
                  {inputRefs?.map((ref, index) => (
                    <div key={index}>
                      <input
                        ref={ref}
                        className="otp-input"
                        type="text"
                        maxLength="1"
                        value={verificationCode[index]}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        onChange={(e) => handleInputChange(e, index)}
                      />
                    </div>
                  ))}
                </div>
              </div>

              <div className="mt-[2rem] flex justify-center w-full">
                {counter.minutes === 0 && counter.seconds === 0 ? (
                  <button
                    disabled={saving}
                    className="label_3 underline"
                    onClick={handleResendOtp}
                    type="button"
                  >
                    Resend code?
                  </button>
                ) : (
                  <span className="label_3 !text-blu">
                    {" "}
                    Resend in {counter.minutes.toString().padStart(2, "0")}:
                    {counter.seconds.toString().padStart(2, "0")}
                  </span>
                )}
              </div>

              <div className="flex items-center justify-center mt-[2rem] w-full">
                <div className="w-[12.4375rem] flex flex-col gap-[.5rem]">
                  <button
                    disabled={saving}
                    onClick={onFinish}
                    className="cstm-btn-2"
                    type="button"
                  >
                    {saving ? <Spin /> : "Next"}
                  </button>

                  <button
                    disabled={saving}
                    onClick={handleClose}
                    className="cstm-btn"
                    type="button"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
