import { createHashRouter } from 'react-router-dom';
import Login from './pages/auth/Login';
import { ProtectedRoute } from './ProtectedRoute';
import Root from './Root'; 
import ForgotPassword from './pages/auth/forgot_pasword/ForgotPassword';
import VerifyEmail from './pages/auth/forgot_pasword/VerifyEmail';
import NewPassword from './pages/auth/forgot_pasword/NewPassword';
import LoginOtpCode from './pages/auth/LoginOtpCode'; 
import PasswordResetSuccess from './pages/auth/forgot_pasword/PasswordResetSuccess'; 
import MyWallet from './pages/wallets/MyWallet';
import MyWalletView from './pages/wallets/MyWalletView';
import SandBox from './pages/admin/Sandbox';
import UsersList from './pages/users/UsersList';
import C2BTable from './pages/payment/C2B/C2BTable';
import RegistrationSuccess from './pages/auth/registration_steps/RegistrationSuccess'; 
import KybDocuments from './pages/auth/verification_documents/KybDocuments'; 
import DirectPaymentTable from './pages/payment/direct_payment/DirectPaymentTable';
import PaymentDetailsTable from './pages/payment/direct_payment/details/PaymentDetailsTable';
import BulkPaymentTable from './pages/payment/bulk-payment/BulkPaymentTable';
import VerificationTable from './pages/payment/bulk-payment/modal/VerificationTable';
import BulkPaymentDetailsTable from './pages/payment/bulk-payment/details/BulkPaymentDetailsTable';
import KyuDocuments from './pages/auth/verification_documents/KyuDocuments';
import UserAddList from './pages/users/user_add/UserAddList';
  

 
import Register from './pages/auth/Register';
import GroupFolderTable from './pages/payment/groups/GroupFolderTable';
import GroupFolderView from './pages/payment/groups/GroupFolderView';
import ContactsListView from './pages/payment/groups/ContactsListView';
import MembersUploadList from './pages/payment/groups/MembersUploadList';
import FundsTransferList from './pages/funds-transfer/FundsTransferList';
import TransferApproval from './pages/approvals/TransferApproval';
import PaymentAprConfTable from './pages/setup/payment/PaymentAprConfTable';
import GroupPaymentTable from './pages/payment/group-payments/GroupPaymentTable';
import GroupPaymentDetailsTable from './pages/payment/group-payments/details/GroupPaymentDetailsTable';
import UtilityTable from './pages/setup/utility/UtilityTable';
import DashboardList from './pages/dashboard/DashboardList';
import SettlementTable from './pages/setup/settlement/SettlementTable';
import AddressDetailsStep from './pages/auth/registration_steps/AddressDetailsStep';
import ProfileList from './pages/profile/ProfileList';
import OtpVerificationTable from './pages/setup/otp-verification/OtpVerificationTable';
import MainReports from './pages/reports/MainReports';
 
export const router = createHashRouter([
	
	{
		path: '/',
		element: <Root />,
		children: [
			{
				path: '/',
				element: <Login />,
				index: true,
			},
			{
				path: '/login',
				element: <Login />,
			},
			{
				path: '/registration',
				element: <Register />,
			},
			{
				path: '/forgot-password',
				element: <ForgotPassword />,
			},
			{
				path: '/verify-email',
				element: <VerifyEmail />,
			},
			{
				path: '/new-password',
				element: <NewPassword />,
			},
			{
				path: '/login-otp',
				element: <LoginOtpCode />,
			},
			{
				path:'/password-reset-success',
				element: <PasswordResetSuccess />,
			},
			{
				path:'/registration-success',
				element: <RegistrationSuccess />,
			},
			{
				path:'/kyu-documents',
				element: <KyuDocuments />,
			},
			{
				path:'/kyb-documents',
				element: <KybDocuments />,
			},
			 
			 
			
			  
			{
				element: <ProtectedRoute role={"EXECUTIVE"} />,
				children: [
					
					{
						path: '/my-wallet',
						element: <MyWallet />,
					},
					{
						path: '/sandbox',
						element: <SandBox />,
					},
					{
						path: '/my-wallet-view',
						element: <MyWalletView />,
					},
					{
						path: '/users',
						element: <UsersList />,
					},
					{
						path: '/c2b',
						element: <C2BTable />,
					},

					{
						path: '/direct-payment',
						element: <DirectPaymentTable />,
					},

					{
						path: '/payment-details',
						element: <PaymentDetailsTable />,
					},

					{
						path: '/bulk-payment',
						element: <BulkPaymentTable />,
					},
					{
						path:'/group-payment',
						element: <GroupPaymentTable />,
					},
					{
						path: '/bulk-verification',
						element: <VerificationTable />,
					},
					{
						path: '/bulk-payment-details',
						element: <BulkPaymentDetailsTable />,
					},
					{
						path: '/group-payment-details',
						element: <GroupPaymentDetailsTable />,
					},
					
					{
						path: '/users-add-list',
						element: <UserAddList />,
					},
				  
					{
						path: '/groups',
						element: <GroupFolderTable />,
					},
					
					{
						path:'/groups/folder/:name',
						element: <GroupFolderView />,
					},
					{
						path:'/contacts/folders/list/:name',
						element: <ContactsListView />,
					},
					{
						path:'/group-members/upload',
						element: <MembersUploadList />,
					},
					{
						path:'/funds-transfer',
						element: <FundsTransferList />,
					},
					{
						path:'/transfer-approval',
						element: <TransferApproval />,
					},
					{
						path:'/payment-approval-setup',
						element: <PaymentAprConfTable />,
					},
					
					{
						path:'/utility-setup',
						element: <UtilityTable />,
					},
					
					{
						path:'/dashboard',
						element: <DashboardList />,
					},
					{
						path:'/settlement-setup',
						element: <SettlementTable />,
					},

					{
						path:'/profile',
						element: <ProfileList />,
					},

					{
						path:'/otp-verification-setup',
						element: <OtpVerificationTable />,
					},
					 
					{
						path:'/reports',
						element: <MainReports />,
					},
					 
					
					
					
				],
			},
			{
				path: '*',
				element: <p>404 - Error: Page not found</p>,
			},
		],
	},
]);
