import { Form, Input, InputNumber, Select, Spin, Table } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchUtility } from "../../features/fetch/fetchSlice";
import { dateForHumans } from "../../utils";
import PhoneInput from "react-phone-input-2";
import toast from "react-hot-toast";
import { save } from "../../features/save/saveSlice";

const { TextArea } = Input;
function Topup(setactive) {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { saving } = useSelector((state) => state.save);
  const { loading, utilityCount } = useSelector((state) => state.fetch);
  const { walletObj } = useSelector((state) => state.obj);
  

  const columns = [
    {
      title: "Name",
      dataIndex: "aprvName",
      sorter: (a, b) => a.aprvName.localeCompare(b.aprvName),
    },

    {
      title: "Status",
      dataIndex: "aprvOrgName",
      sorter: (a, b) => a.aprvOrgName.localeCompare(b.aprvOrgName),
    },
    {
      title: "Created Date",
      dataIndex: "transCreatedDate",
      render: (item) => {
        return <div>{dateForHumans(item)}</div>;
      },
      sorter: (a, b) => a.transCreatedDate - b.transCreatedDate,
    },
  ];

  const onFinish = async (data) => {
    data.walCode = walletObj?.walCode;
    const res = await dispatch(
      save({
        ...data,
        url: "api/v1/stk/push",
      })
    );
    if (res?.payload?.success) {
      await toast.success(res?.payload?.messages?.message);
      await navigate("/my-wallet");
      await form.resetFields();
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  };

  const handlePrevious = async () => {
    await navigate("/my-wallet");
  };

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  return (
    <div className="flex lg:flex-row flex-col gap-10">
      <div className="lg:w-[40%] w-full">
        <Form
          layout="vertical"
          ref={formRef}
          name="control-ref"
          onFinish={onFinish}
          className=" "
          style={{
            maxWidth: "100%",
          }}
          form={form}
          // initialValues={prodd}
        >
          <Form.Item
            className="table-form-item"
            name="mobileNo"
            label="Phone Number"
            rules={[
              {
                required: true,
                message: "Please add Phpne Number",
              },
              {
                validator: (_, value) => {
                  if (value && value.length === 12) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Phone number must be exactly 10 characters")
                  );
                },
              },
            ]}
          >
            <PhoneInput
              country="ke"
              countryCodeEditable={false}
              className="input"
            />
          </Form.Item>

          <Form.Item
            name="amount"
            label="Amount"
            rules={[
              {
                required: true,
                message: "Enter amount",
              },
            ]}
          >
            <InputNumber
              min={0}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
              placeholder="Enter amount"
              className="input flex"
            />
          </Form.Item>

          <div className="flex lg:flex-row flex-col justify-between mt-[56px] mb-[48px]">
            <div className="justify-start"></div>
            <div className="justify-end flex items-center gap-x-2">
              <div className="w-[200px]">
                <button
                  key="back"
                  type="button"
                  onClick={handlePrevious}
                  className="cstm-btn"
                >
                  Previous
                </button>
              </div>

              <div className="w-[200px]">
                <button
                  key="submit"
                  type="submit"
                  className="cstm-btn-2"
                  disabled={saving}
                >
                  {saving ? <Spin /> : "Create"}
                </button>
              </div>
            </div>
          </div>
        </Form>
      </div>
      {/* <div className="lg:w-[60%] w-full">
        <span className="heading_4">Transaction History</span>
        <Table
          rowSelection={false}
          className="mt-[1.31rem] w-full"
          scroll={{
            x: 800,
          }}
          rowKey={(record) => record?.aprvId}
          columns={columns}
          dataSource={[]}
          loading={loading}
          pagination={{
            position: ["bottomCenter"],
            current: pageIndex + 1,
            total: utilityCount,
            pageSize: pageSize,
            onChange: (page, size) => {
              setPageIndex(page - 1);
              setPageSize(size);
              // fetchUtilityData(page - 1, size);
            },
            showSizeChanger: false,
            hideOnSinglePage: true,
          }}
        />
      </div> */}
    </div>
  );
}

export default Topup;
