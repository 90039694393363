import React, { useEffect, useRef, useState } from 'react';
import { Checkbox, Form, Input, InputNumber, Modal, Select, Spin, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast'; 
import svg59 from './../../../../assets/svg/svg59.svg';
import svg60 from './../../../../assets/svg/svg60.svg';
import deleteRed from './../../../../assets/svg/delete-red.svg'; 
import bankTemplate from './../../../../assets/temp/NDOVUPAYBANKTEMPLATE.xlsx';
import mpesaTemplate from './../../../../assets/temp/NDOVUPAYMPESATEMPLATE.xlsx';
import { fetchPaymentModes, fetchSubwalletDropdown } from '../../../../features/fetch/fetchSlice';
import { paymentMethodsData } from '../../../../data';
import { saveFileUpload, saveFileUploadBulk } from '../../../../features/save/saveSlice';

const { TextArea } = Input;

const BulkPaymentModal = ({ open, handleCancel, handleFetchData, setopenSingle }) => {
    const [form] = Form.useForm();
    const formRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { banks, paymentModes, dropLoading, mainWalletData, subwalletData } = useSelector((state) => state.fetch);

    const { saving } = useSelector((state) => state.save);
    const { singlePaymentObj } = useSelector((state) => state.obj);

    const [paymentSourceName, setPaymentSourceName] = useState();
    const [paymentModeName, setPaymentModeName] = useState();

    const [openVerification, setopenVerification] = useState(false);
    async function handleCancelVerification() {
        await setopenVerification(false);
        await setopenSingle(true);
    }
    async function handleOpenVerification() {
        await setopenVerification(true);
        await setopenSingle(false);
        await handleCancel();
    }

    async function handleCancelSingle() {
        await handleCancel();
        await form.resetFields();
        await setShow();
    }

    const [file, setFile] = useState();
    const [formData, setFormData] = useState();
    const [show, setShow] = useState();

    const onChangeOther = (value, fieldName) => {
        setFormData((prev) => ({
            ...prev,
            [fieldName]: value,
        }));
    };

    async function handleSelectChange(value, formName) {
        await setFormData((prevData) => ({
            ...prevData,
            [formName]: value,
        }));
    }

    const onFinish = async (data) => {
        // await dispatch(setSinglePaymentObj(data));
        // await handleOpenVerification();
        const formData = new FormData();
        if (file) {
            formData.append('file', file);
        } else {
            toast.error('File is null or undefined');
            return;
        }
        const res = await dispatch(
        saveFileUploadBulk({
          formData, 
          url:show === 'BANK' ? `bulk-payment-details/upload-bank?blkuPmUid=${data?.blkuPmUid}&blkuWalUid=${data?.blkuWalUid}`:show === 'MPESA' ?`bulk-payment-details/upload-mpesa?blkuPmUid=${data?.blkuPmUid}&blkuWalUid=${data?.blkuWalUid}` :null,
        }))
        console.log("res?.payload?",res?.payload)
        if (res?.payload?.success) {
            await toast.success(res?.payload?.messages?.message); 
            await form.resetFields();
            await setFile('');
            await setShow('')
            await navigate('/bulk-verification')
        } else {
            toast.error(res?.payload?.messages?.message);
        }
    };

    async function fetchSubWalletData() {
        dispatch(
            fetchSubwalletDropdown({
                url: `api/v1/wallet/sub/${mainWalletData?.walUid}`,
            })
        );
    }

    async function fetchPaymentModesData() {
        await dispatch(
            fetchPaymentModes({
                url: 'api/v1/payments/fetch-payment-modes',
            })
        );
    }

   
    return (
        <>
            <Modal className="modal-btn mod-height mod-footer" title={`Upload Bulk Payment`} open={open} onOk={onFinish} onCancel={handleCancelSingle} width={850} maskClosable={false}>
                <Form
                    layout="vertical"
                    ref={formRef}
                    name="control-ref"
                    onFinish={onFinish}
                    className=" "
                    style={{
                        maxWidth: '100%',
                    }}
                    form={form}
                    // initialValues={singlePaymentObj}
                >
                    <Form.Item
                        name="blkuWalUid"
                        className=""
                        label={<span>Payment Source</span>}
                        rules={[
                            {
                                required: true,
                                message: 'Please select payment source',
                            },
                        ]}
                    >
                        <Select
                            className=""
                            allowClear
                            style={{
                                width: '100%',
                            }}
                            options={
                                subwalletData?.length > 0 &&
                                subwalletData?.map((item) => ({
                                    value: item?.walUid,
                                    label:
                                    item?.walName +
                                    " - " + item?.walCurrency +  " " +
                                    item?.walBalAmount 
                                }))
                            }
                            onChange={(value) => {
                                const selectedItem = subwalletData?.find((item) => item?.walUid === value);
                                setPaymentSourceName(selectedItem?.walName || '');
                            }}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label?.toLocaleLowerCase() ?? '').includes(input?.toLocaleLowerCase())}
                            onDropdownVisibleChange={() => {
                                fetchSubWalletData();
                            }}
                            dropdownRender={(menu) => (
                                <>
                                    {dropLoading && (
                                        <div
                                            style={{
                                                padding: '8px',
                                                textAlign: 'center',
                                            }}
                                        >
                                            <Spin className="sms-spin" />
                                        </div>
                                    )}
                                    {menu}
                                </>
                            )}
                        />
                    </Form.Item>

                    <Form.Item
                        name="pdTransType"
                        className=""
                        label={<span>Payment Method</span>}
                        rules={[
                            {
                                required: true,
                                message: 'Please select payment method',
                            },
                        ]}
                    >
                        <Select
                            className=""
                            allowClear
                            style={{
                                width: '100%',
                            }}
                            options={
                                paymentMethodsData?.length > 0 &&
                                paymentMethodsData?.map((item) => ({
                                    value: item?.value,
                                    label: item?.label,
                                }))
                            }
                            onChange={(value) => {
                                setShow(value);
                            }}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label?.toLocaleLowerCase() ?? '').includes(input?.toLocaleLowerCase())}
                            onDropdownVisibleChange={() => {}}
                        />
                    </Form.Item>
                    {show === 'BANK' && (
                        <>
                            <Form.Item
                                name="blkuPmUid"
                                className=""
                                label={<span>Payment Mode</span>}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select Payment Mode',
                                    },
                                ]}
                            >
                                <Select
                                    className=""
                                    allowClear
                                    style={{
                                        width: '100%',
                                    }}
                                    options={
                                        paymentModes?.length > 0 &&
                                        paymentModes?.map((item) => ({
                                            value: item?.pmUid,
                                            label: item?.pmName,
                                        }))
                                    }
                                    onChange={(value) => {
                                        const selectedItem = paymentModes?.find((item) => item?.pmUid === value);
                                        setPaymentModeName(selectedItem?.pmName || '');
                                    }}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (option?.label?.toLocaleLowerCase() ?? '').includes(input?.toLocaleLowerCase())}
                                    onDropdownVisibleChange={() => {
                                        fetchPaymentModesData();
                                    }}
                                    dropdownRender={(menu) => (
                                        <>
                                            {dropLoading && (
                                                <div
                                                    style={{
                                                        padding: '8px',
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Spin className="sms-spin" />
                                                </div>
                                            )}
                                            {menu}
                                        </>
                                    )}
                                />
                            </Form.Item>

                            <a href={bankTemplate} type="button" download>
                                <div className=" flex items-end">
                                    <Tooltip title="Download file ">
                                        <img src={svg59} alt="svg59" />
                                    </Tooltip>
                                    <span className="ml-3 text-[15px] font-bold font-dmSans">Click to download bank template</span>
                                </div>
                            </a>
                        </>
                    )}

                    {show === 'MPESA' && (
                        <>
                            <a href={mpesaTemplate} type="button" download>
                                <div className=" flex items-end">
                                    <Tooltip title="Download file ">
                                        <img src={svg59} alt="svg59" />
                                    </Tooltip>
                                    <span className="ml-3 text-[15px] font-bold font-dmSans">Click to download mpesa template</span>
                                </div>
                            </a>
                        </>
                    )}

                    <div className="dropzone-wrapper mt-5 mb-5">
                        <div className="dropzone-desc">
                            <img className="" src={svg60} alt="svg60" />
                            <p className="ant-upload-text text-[#7E93AE] text-[14px] font-[400]">
                                Drag & Drop or <span className="text-[#010101] font-[500]">Choose file</span> to upload
                            </p>
                            <p className="ant-upload-hint text-[#010101] font-[500]">.xlsx</p>
                        </div>
                        <input 
                        accept=".xls,.xlsx"
                         onChange={(e) => setFile(e.target.files[0])} type="file" name="file" className="dropzone" />
                    </div>

                    {file?.name && (
                        <div className="flex items-center justify-between my-2">
                            <p className="text-[16px] f0nt-[600] text-[#010101] w-[80%] truncate">{file?.name}</p>
                            <img src={deleteRed} alt="deleteRed" onClick={() => setFile(null)} className="text-[red] text-[25px] cursor-pointer" />
                        </div>
                    )}

                    <div className="flex lg:flex-row flex-col justify-between mt-[56px] mb-[48px]">
                        <div className="justify-start"></div>
                        <div className="justify-end flex items-center gap-x-2">
                            <div className="w-[200px]">
                                <button key="back" type="button" onClick={handleCancelSingle} className="cstm-btn">
                                    Cancel
                                </button>
                            </div>

                            <div className="w-[200px]">
                                <button key="submit" type="submit" className="cstm-btn-2" disabled={saving}>
                                    {saving ? <Spin /> : 'Submit'}
                                </button>
                            </div>
                        </div>
                    </div>
                </Form>
            </Modal>
            {/* <VerificationModal handleFetchData={handleFetchData} open={openVerification} setopenVerification={setopenVerification} handleCancel={handleCancelVerification} /> */}
        </>
    );
};
export default BulkPaymentModal;
