import { Avatar, Descriptions, Modal, Spin } from "antd";
import {
  capitalize,
  capitalizeFirstLetter,
  formatMoney,
  getInitials,
  getRandomColor,
} from "../../../utils";
import { useEffect } from "react";
import {  useSelector } from "react-redux";
 
export default function FundsTransferInfoModal({
  open,
  handleOkay,
  selectedItem,
  reimbursement,
  
}) {
  // const fetchUrl = selectedItem?.apvId ? `${applicationUrl().url}/api/v1/approval/approved/${selectedItem?.apvRefId}` : null;

  // const { isLoading, data } = useFetch(fetchUrl);
  // const wallets = useFetch(fetchWalletsUrl);
  // const otherItems = useFetch(fetchUrlOther);

  // let accWallet = wallets?.data?.find((item) => item?.customerWallet?.cwCode === selectedItem?.details?.withDrawCwCollection);
  const wallets = [];
  const otherItems = [];
  const accWallet = [];
  const isLoading = false;
  const data = [];
  const {approvalInfo} =  useSelector((state)=>state.fetch)
console.log("approvalInfo",approvalInfo)

 
  const items = [
    {
      key: "1",
      label: "Source account",
      children: wallets.isLoading ? (
        <Spin className="blu_spin text-left mt-[1.25remrem]" />
      ) : (
        <span>
          {capitalize(approvalInfo?.sourceAccount)} |{" "}
          {String(accWallet?.customerWallet?.cwCode).toUpperCase()}
        </span>
      ),
    },
    {
      key: "2",
      label: "Recipient",
      children: 
          <span>{approvalInfo?.recipient}</span>
        
    },
    {
      key: "3",
      label: "Amount",
      children: formatMoney(approvalInfo?.amount, "KES"),
    },
    {
      key: "4",
      label: "Transfer reason",
      children: (
        <span>
          {capitalizeFirstLetter(approvalInfo?.reason)}
        </span>
      ),
    },
  ];

  useEffect(() => {}, [wallets.data, selectedItem]);
 
  return (
    <>
      <Modal
        centered
        className="modal-btn mod-height mod-footer"
        title={"Review request"}
        open={open}
        width={500}
        footer={false}
        onCancel={handleOkay}
      >
        <div className="px-[2.94rem] pt-[.19rem] w-full h-full">
          <div className="w-full flex flex-col">
            <div className="grid grid-cols-1 gap-[.94rem]">
              <span className="label_1 mt-[1.25rem]">
                {selectedItem?.procAprvIntiatorUsrName} has made the following request{" "}
              </span>

              <div className="w-full">
                <Descriptions
                  title={false}
                  items={items}
                  column={1}
                  colon={false}
                  size="small"
                />
              </div>

              {reimbursement && otherItems.isLoading ? (
                <Spin className="blu_spin text-left mt-[1.25remrem]" />
              ) : reimbursement &&
                !otherItems.isLoading &&
                otherItems?.data?.length ? (
                <>
                  <span className="label_1 mt-[1.25rem] underline">
                    Items:{" "}
                  </span>
                  {otherItems?.data?.map((item, index) => {
                    return (
                      <div key={index}>
                        <div className="flex items-center gap-[.25rem]">
                          <span className="label_2 flex min-w-[5rem] w-auto">
                            {item?.wtdName}|{" "}
                          </span>
                          <span className="paragraph_1">
                            {formatMoney(item?.wtdAmount, "KES")}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : null}

              {isLoading ? (
                <Spin className="blu_spin text-left mt-[1.25remrem]" />
              ) : !isLoading && approvalInfo?.reviewers?.length ? (
                <>
                  <span className="label_1 mt-[1.25rem]">
                    The following signitories have already approved{" "}
                  </span>
                  {approvalInfo?.reviewers?.map((item, index) => {
                    return (
                      <div key={index} className="mt-[.5rem]">
                        <div className="flex items-center gap-[.25rem]">
                          <Avatar
                            style={{
                              backgroundColor: `#${getRandomColor()}`,
                            }}
                          >
                            {getInitials(item?.procAprvUsrName)}
                          </Avatar>
                          <span>{item?.procAprvUsrName}</span>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : null}
            </div>

            <div className="flex items-center justify-center my-[3rem] w-full">
              <div className="w-[12.4375rem] flex items-center gap-[.5rem]">
                <button
                  onClick={handleOkay}
                  className="cstm-btn-2"
                  type="button"
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
