import { Dropdown, Input, Table } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import svg27 from "../../../assets/svg/svg27.svg";
import AddGroupModal from "./modal/AddGroupModal";
import { setActiveObj } from "../../../features/obj/objSlice";
import DeleteModal from "../../../components/DeleteModal";
import BreadCrumb from "../../../layout/BreadCrumb";
import flash from "../../../assets/svg/Flash.svg";
import { capitalize } from "../../../utils";
import Search from "../../../components/Search";
import { fetchPaymentGroups } from "../../../features/fetch/fetchSlice";
import { deleteGroup } from "../../../features/delete-setting/deleteSlice";

export default function GroupFolderTable() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, paymentGroups } = useSelector((state) => state.fetch);

  const { deleteLoading } = useSelector((state) => state.setting);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [hasSelected, sethasSelected] = useState(false);
  const [activeFolder, setactiveFolder] = useState({});
  const [search, setsearch] = useState("");

  const [open, setopen] = useState(false);

  function handleAddGroup() {
    setopen(true);
  }

  function handleCancel() {
    setopen(false);
  }

  const [searchInput, setsearchInput] = useState("");

  const handleSearch = (e) => {
    setsearchInput(e.target.value);
  };

  const filterDataList = searchInput
    ? paymentGroups?.filter((item) =>
        item?.pgName?.toLowerCase().includes(searchInput.toLowerCase())
      )
    : paymentGroups;

  async function handleFolderChange(item) {
    await dispatch(setActiveObj(item));
    await navigate(`/groups/folder/${item?.pgName}`);
  }

  const [prodd1, setProdd1] = useState("");
  const [isModalOpenDelete, setIsModalOpenDelete] = useState(false);
  const showModalDelete = async () => {
    setIsModalOpenDelete(true);
  };
  const handleDelete = async () => {
    const res = await dispatch(
      deleteGroup({
        pgId: prodd1?.pgId,
      })
    );
    if (res?.payload?.success) {
      await toast.success(res?.payload?.messages?.message);
      handleFetch();
      await setIsModalOpenDelete(false);
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  };

  const handleView = () => {
    handleFolderChange(prodd1);
  };

  const handleEdit = () => {
    setopen(true);
  };

  const settingItems = [
    {
      key: "0",
      label: (
        <Link
          onClick={handleView}
          className="flex  text-[16px] font-sans   !text-darkGreen"
        >
          View
        </Link>
      ),
    },

    {
      key: "1",
      label: (
        <Link
          onClick={handleEdit}
          className="flex  text-[16px] font-sans  !text-darkGreen"
        >
          Edit
        </Link>
      ),
    },

    {
      key: "2",
      label: (
        <div
          onClick={showModalDelete}
          className="flex  text-[16px] font-sans   !text-darkGreen"
        >
          Remove
        </div>
      ),
    },
  ];

  const columns = [
    {
      title: "Group name",
      sorter: (a, b) => a.pgName.localeCompare(b.pgName),
      render: (item) => (
        <div
          className="cursor-pointer"
          onClick={() => handleFolderChange(item)}
        >
          {item?.pgName}
        </div>
      ),
    },
    {
      title: "Group description",
      dataIndex: "pgDesc",
      sorter: (a, b) => a.pgDesc.localeCompare(b.pgDesc),
    },
    {
      title: "Creator",
      dataIndex: "pgCreatedByName",
      sorter: (a, b) =>
        a.pgCreatedByName.localeCompare(b.pgCreatedByName),
    },
    {
      title: "Date Created",
      dataIndex: "groupCreationDate",
      sorter: (a, b) => a.groupCreationDate.localeCompare(b.groupCreationDate),
      render: (item) => <span>{moment(item).format("Do MMMM YYYY")}</span>,
    },
    {
      title: "Actions",
      render: (item) => (
        <>
          <Dropdown
            overlayStyle={{
              width: "250px",
            }}
            trigger={"click"}
            menu={{ items: settingItems }}
            placement="bottom"
          >
            <button onClick={() => setProdd1(item)}>
              <img src={svg27} alt="svg27" />
            </button>
          </Dropdown>
        </>
      ),
    },
    // {
    //   title: "",
    //   render: (item) => (
    //     <>
    //       <button
    //         className="text-blueDark"
    //         onClick={() => handleFolderChange(item)}
    //       >
    //         view
    //       </button>
    //     </>
    //   ),
    // },
  ];

  const handleSelectTemplate = async () => {
    navigate("/templates");
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = async () => {
    setIsModalOpen(true);
  };

  const settingItemsQuickActions = [
    {
      key: "1",
      label: (
        <div
          onClick={showModal}
          className="font-dmSans text-[#344054] font-[500] text-[18px] !mt-[5%]"
        >
          Send sms
        </div>
      ),
    },
  ];

  const [rowId, setRowId] = useState([]);

  const handleEmployeeToReturns = async (selectedRows) => {
    await setRowId(selectedRows);
    // await showModalGroup();
  };

  const onSelectChange = (keys, rows) => {
    setSelectedRowKeys(keys);
    handleEmployeeToReturns(rows);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  async function handleFetch() {
    await dispatch(
      fetchPaymentGroups({
        url: "api/v1/payments/fetch-payment-groups",
      })
    );
  }

  useEffect(() => {
    handleFetch();
  }, []);

  const breadList = [
    {
      title: (
        <div className="flex items-center gap-[.25rem]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <g clipPath="url(#clip0_627_13942)">
              <path
                d="M13.7899 6.89067L7.75241 0.856289L7.34772 0.451602C7.25551 0.360003 7.13082 0.308594 7.00085 0.308594C6.87087 0.308594 6.74618 0.360003 6.65397 0.451602L0.211783 6.89067C0.117301 6.98478 0.0426296 7.09687 -0.00782254 7.22031C-0.0582747 7.34376 -0.0834854 7.47607 -0.0819665 7.60942C-0.0757165 8.15942 0.382096 8.59848 0.932096 8.59848H1.59616V13.6875H12.4055V8.59848H13.0837C13.3508 8.59848 13.6024 8.49379 13.7915 8.30473C13.8846 8.21193 13.9583 8.10159 14.0085 7.98009C14.0586 7.8586 14.0842 7.72836 14.0837 7.59692C14.0837 7.33129 13.979 7.07973 13.7899 6.89067ZM7.87585 12.5625H6.12585V9.37504H7.87585V12.5625ZM11.2805 7.47348V12.5625H8.87585V9.00004C8.87585 8.65473 8.59616 8.37504 8.25085 8.37504H5.75085C5.40553 8.37504 5.12585 8.65473 5.12585 9.00004V12.5625H2.72116V7.47348H1.22116L7.00241 1.69691L7.36335 2.05785L12.7821 7.47348H11.2805Z"
                fill="black"
                fillOpacity="0.45"
              />
            </g>
            <defs>
              <clipPath id="clip0_627_13942">
                <rect width="14" height="14" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span>Home</span>
        </div>
      ),
      href: "/#/home",
    },
    {
      title: capitalize("Groups"),
    },
  ];

  return (
    <div className="w-full h-full overflow-y-scroll ">
      <div className="justify-between items-center white_card">
        <BreadCrumb
          breadList={breadList}
          header={"List of Groups"}
          info={"Manage your Groups here to make payments"}
          btn={true}
          btnTitle="Add Group"
          btnSvg={flash}
          onClick={handleAddGroup}
        />
      </div>
      <div className="flex flex-col  w-full mt-[1.25rem] white_card">
        <h3 className="font-[700] text-[24px] text-black1 dash-title ">
          Payments groups
        </h3>
        <div className="mt-5 flex lg:flex-row flex-col gap-y-5 justify-between lg:items-center items-start w-full">
          <div className="w-[16.8125rem]">
            <Search
              searchInput={searchInput}
              handleSearch={handleSearch}
              text={"Search group"}
            />
          </div>
          <div className="flex lg:flex-row flex-col lg:items-center items-start lg:gap-x-10 gap-y-5">
            {rowId && rowId?.length > 0 && (
              <Dropdown
                overlayStyle={{
                  width: "250px",
                }}
                trigger={"click"}
                menu={{ items: settingItemsQuickActions }}
                placement="bottom"
              >
                <div className="w-[250px]">
                  <button className="cstm-btn" type="submit">
                    Quick actions
                  </button>
                </div>
              </Dropdown>
            )}
          </div>
        </div>

        <div className="dash-inner-page mt-[24px] max-w-full w-full overflow-x-auto">
          <section>
            <Table
              rowSelection={false}
              // rowSelection={rowSelection}
              className="mt-[1.31rem] w-full"
              scroll={{
                x: 800,
              }}
              rowKey={(record) => record?.pgId}
              columns={columns}
              dataSource={filterDataList}
              loading={loading}
            />
          </section>
        </div>
      </div>

      <DeleteModal
        isModalOpen={isModalOpenDelete}
        setIsModalOpen={setIsModalOpenDelete}
        prodd={prodd1}
        handleDelete={handleDelete}
        loading={deleteLoading}
        content={`Are you sure you want to delete ${prodd1?.pgName}?`}
        title={`Remove ${prodd1?.pgName}`}
      />

      <AddGroupModal
        handleFetchData={handleFetch}
        open={open}
        handleCancel={handleCancel}
        prodd={prodd1}
      />

      {/* <SmsManyGroupModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        rowIdTableGroup={rowId}
      /> */}
    </div>
  );
}
