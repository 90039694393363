import bankSvg from "../../assets/svg/bank.svg";
import { cashConverter } from "../../utils";
  
export default function DashCard({dashboardData}) { 
  const svgMap = {
    bankSvg
  };
  const processedData = dashboardData?.items?.map((item) => ({
    ...item,
    svg: svgMap[item?.svg],
  }));

  return (
    <div className="mt-[.2rem] flex flex-wrap w-full lg:w-[70%] gap-y-[1rem] gap-x-[1rem] ">
      {processedData?.length > 0 &&
        processedData?.map((item) => (
          <div
            key={item?.title}
            style={{
              background: item?.bgColor,
            }}
            className="flex-shrink-0 w-full sm:w-[calc(50%-0.5rem)] lg:w-[calc(33.33%-0.67rem)] max-h-[200px] h-[130px] px-3 py-6 rounded-lg"
          >
            <p className="text-[14px] font-[600] text-white">{item?.title}</p>
            <div className="flex flex-col">
              <h5 className="mb-1 text-[22px] font-[600] text-white w-full">
                {cashConverter(item?.value)}
              </h5>
              {item?.walName && (
                <div className="flex mb-1 !items-center">
                  <img src={item?.svg} alt="bankSvg" />
                  <h5 className="text-[15px] font-[500] text-white w-full ml-1 mt-1">
                    {item?.walCode} - {item?.walName}
                  </h5>
                </div>
              )}
            </div>
          </div>
        ))}
    </div>
  );
}
