import React, { useEffect, useState } from "react";
import {
  capitalize,
  dateForHumans,
  numberWithCommas,
  timeAmPm,
} from "../../utils";
import FunnelSimple from "../../assets/svg/FunnelSimple.svg";
import TransferModal from "./modal/TransferModal";
import { clearObj } from "../../features/obj/objSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchFundsTransfer, fetchSettlement } from "../../features/fetch/fetchSlice";
import FilterModal from "./modal/FilterModal";
import BreadCrumb from "../../layout/BreadCrumb";
import flash from "../../assets/svg/Flash.svg";
import useModalToggle from "../../custom_hooks/useModalToggle";
import svg38 from "../../assets/svg/svg38.svg";
import svg32 from "../../assets/svg/svg32.svg";
import { Table } from "antd";
import { useNavigate } from "react-router-dom";
import moment from "moment";

function FundsTransferList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { open, handleCancel, handleOpen } = useModalToggle();

  const { fundsTransferCount, fundsTransfer, loading } = useSelector(
    (state) => state.fetch
  );

  const [filters, setFilters] = useState([]);
  const [formData, setFormData] = useState({});
  const showModalFilter = () => {
    handleOpen();
  };

  const handleClearFilters = async () => {
    await setFormData({});
    await setFilters([]);
  };

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [openTransfer, setOpenTransfer] = useState(false);
  const handleOpenTransfer = async () => {
    await setOpenTransfer(true);
    await dispatch(clearObj());
  };
  const handleCancelTransfer = () => {
    setOpenTransfer(false);
  };

  const handleView = async (item) => {};
  const breadList = [
    {
      title: (
        <div className="flex items-center gap-[.25rem]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <g clipPath="url(#clip0_627_13942)">
              <path
                d="M13.7899 6.89067L7.75241 0.856289L7.34772 0.451602C7.25551 0.360003 7.13082 0.308594 7.00085 0.308594C6.87087 0.308594 6.74618 0.360003 6.65397 0.451602L0.211783 6.89067C0.117301 6.98478 0.0426296 7.09687 -0.00782254 7.22031C-0.0582747 7.34376 -0.0834854 7.47607 -0.0819665 7.60942C-0.0757165 8.15942 0.382096 8.59848 0.932096 8.59848H1.59616V13.6875H12.4055V8.59848H13.0837C13.3508 8.59848 13.6024 8.49379 13.7915 8.30473C13.8846 8.21193 13.9583 8.10159 14.0085 7.98009C14.0586 7.8586 14.0842 7.72836 14.0837 7.59692C14.0837 7.33129 13.979 7.07973 13.7899 6.89067ZM7.87585 12.5625H6.12585V9.37504H7.87585V12.5625ZM11.2805 7.47348V12.5625H8.87585V9.00004C8.87585 8.65473 8.59616 8.37504 8.25085 8.37504H5.75085C5.40553 8.37504 5.12585 8.65473 5.12585 9.00004V12.5625H2.72116V7.47348H1.22116L7.00241 1.69691L7.36335 2.05785L12.7821 7.47348H11.2805Z"
                fill="black"
                fillOpacity="0.45"
              />
            </g>
            <defs>
              <clipPath id="clip0_627_13942">
                <rect width="14" height="14" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span>Home</span>
        </div>
      ),
      href: "/#/home",
    },
    {
      title: capitalize("Funds Transfer"),
    },
  ];

  const columns = [
    {
      title: "Created Date",
      dataIndex: "transaCreatedTime",
      render: (item) => (
        <span>{moment(item).format("Do MMM YYYY, h:mm:ss A")}</span>
      ),
      sorter: (a, b) =>
        new Date(a.transaCreatedTime) - new Date(b.transaCreatedTime),
    },

    {
      title: "Source Name",
      dataIndex: "transaSourceWalletName",
      sorter: (a, b) =>
        a.transaSourceWalletName.localeCompare(b.transaSourceWalletName),
    },
    {
      title: "Benefitiary Acc",
      dataIndex: "transaTargetWalletName",
      sorter: (a, b) =>
        a.transaTargetWalletName.localeCompare(b.transaTargetWalletName),
    },
    {
      title: "Transfer Reason",
      dataIndex: "transaReason",
      sorter: (a, b) => a.transaReason.localeCompare(b.transaReason),
    },

    {
      title: "Amount (KES)",
      dataIndex: "transaAmount",
      render: (item) => {
        return <div>{numberWithCommas(item)}</div>;
      },
      sorter: (a, b) => a.transaAmount - b.transaAmount,
    },
    {
      title: "Request By",
      dataIndex: "transaCreatedByName",
      sorter: (a, b) =>
        a.transaCreatedByName.localeCompare(b.transaCreatedByName),
    },

    {
      title: "Status",
      dataIndex: "transaStatus",
      render: (item) => {
        return (
          <div
            className={`${
              item === "PENDING" ? "bg-[#FFA500]" : "bg-darkGreen"
            }  text-[#FFf]  inline-block rounded-[5px] px-2 py-1 w-auto`}
          >
            {item}
          </div>
        );
      },
    },

    {
      title: "Info",
      render: (item) => (
        <button onClick={() => handleView(item)}>
          <img src={svg32} alt="svg32" />
        </button>
      ),
    },
  ];

  const [activeBtn, setActiveBtn] = useState(null);
  const today = new Date();

  const handleFetchDayData = () => {
    //   if(activeBtn === "WEEK"){
    //     dispatch(fetchDash({
    //       msgDateFrom: getDate7DaysAgo(),
    //       msgDateTo: formatDate(today),
    //         url: "api/v2/dash",
    //       }));
    //   }
    //   if(activeBtn === "MONTH"){
    //     dispatch(fetchDash({
    //       msgDateFrom: getDate30DaysAgo(),
    //       msgDateTo: formatDate(today),
    //         url: "api/v2/dash",
    //       }));
    //   }
  };
  const handleClick = async (item) => {
    await setActiveBtn(item);
    await handleFetchDayData();
  };

  async function fetchFundsTransferData(page, size) {
    dispatch(
      fetchFundsTransfer({
        url: "api/v1/wallet/fetch-fund-transfers",
        limit: size ?? pageSize,
        start: page ?? pageIndex,
        transaStatus: activeBtn,
      })
    );
  }


  async function fetchSettlementData() {
    dispatch(
      fetchSettlement({
        url: "api/v1/wallet/fetch-settlements", 
      })
    );
  }

  useEffect(()=>{
    fetchSettlementData()
  },[])


  useEffect(() => {
    fetchFundsTransferData();
  }, [activeBtn]);

  return (
    <div className="w-full h-full overflow-y-scroll">
      <div className="justify-between items-center white_card">
        <BreadCrumb
          breadList={breadList}
          header={"Funds Transfer"}
          info={"Here’s an overview of your Funds Transfer"}
          btn={true}
          btnTitle="Funds Transfer"
          btnSvg={flash}
          onClick={handleOpenTransfer}
        />
      </div>
      <div className="bg-white rounded-[10px] mt-[1.25rem] h-full">
        <div className="flex flex-col w-full lg:px-10 px-3 ">
          <h3 className="font-[700] text-[24px] text-lightBlack dash-title mt-[1.25rem]">
            Funds Transfer Details
          </h3>
          <div className="mt-5 flex lg:flex-row flex-col gap-y-5 justify-between lg:items-center items-start w-full">
            <div className="flex items-center">
              <span>
                {" "}
                <button
                  onClick={showModalFilter}
                  type="button"
                  className={`bg-transparent flex items-center gap-x-'1' ${
                    Object?.keys(formData)?.length > 0
                      ? "!text-[#5688E5]"
                      : "inherit"
                  }`}
                >
                  <img src={FunnelSimple} alt="FunnelSimple" />
                  Filters
                </button>
              </span>
              {Object?.keys(formData)?.length > 0 && (
                <span className="flex items-center text-[#5688E5] cursor-pointer ml-1">
                  :{Object?.keys(formData)?.length}
                  <img src={svg38} alt="svg38" onClick={handleClearFilters} />
                </span>
              )}
            </div>
          </div>

          <div className="flex items-center gap-x-14 mb-2  w-auto p-1.5">
            <button
              onClick={() => handleClick(null)}
              className={`${
                activeBtn === null
                  ? "text-[#146ADC] border border-[#146ADC]"
                  : ""
              }  !rounded-[4px]   flex items-center gap-x-5 py-1 px-5 font-[500]`}
            >
              <span className="w-[7px] h-[7px] rounded-full bg-[#146ADC]"></span>{" "}
              All
            </button>
            <button
              onClick={() => handleClick("TRANSFERED")}
              className={`${
                activeBtn === "TRANSFERED"
                  ? "text-[#2DD683] border border-[#2DD683]"
                  : ""
              }  !rounded-[4px]   flex items-center gap-x-5 py-1 px-5 font-[500]`}
            >
              <span className="w-[7px] h-[7px] rounded-full bg-[#2DD683]"></span>{" "}
              Transfered
            </button>

            <button
              onClick={() => handleClick("PENDING")}
              className={`${
                activeBtn === "PENDING"
                  ? "text-[#FFAB00] border border-[#FFAB00]"
                  : ""
              }  !rounded-[4px]   flex items-center gap-x-5 py-1 px-5 font-[500]`}
            >
              <span className="w-[7px] h-[7px] rounded-full bg-[#FFAB00]"></span>{" "}
              Pending
            </button>
            <button
              onClick={() => handleClick("REJECTED")}
              className={`${
                activeBtn === "REJECTED"
                  ? "text-[#9D1500] border border-[#9D1500]"
                  : ""
              }  !rounded-[4px]   flex items-center gap-x-5 py-1 px-5 font-[500]`}
            >
              <span className="w-[7px] h-[7px] rounded-full bg-[#9D1500]"></span>{" "}
              Rejected
            </button>
          </div>

          <Table
            rowSelection={false}
            className="mt-[1.31rem] w-full"
            scroll={{
              x: 1200,
            }}
            rowKey={(record) => record?.blkBlkuUid}
            columns={columns}
            dataSource={fundsTransfer}
            loading={loading}
            pagination={{
              position: ["bottomCenter"],
              current: pageIndex + 1,
              total: fundsTransferCount,
              pageSize: pageSize,
              onChange: (page, size) => {
                setPageIndex(page - 1);
                setPageSize(size);
                fetchFundsTransferData(page - 1, size);
              },
              showSizeChanger: false,
              hideOnSinglePage: true,
            }}
          />
        </div>
      </div>

      <FilterModal
        isModalOpen={open}
        handleCancel={handleCancel}
        formData={formData}
        setFormData={setFormData}
        filters={filters}
        setFilters={setFilters}
      />

      <TransferModal
        handleFetchData={fetchFundsTransferData}
        open={openTransfer}
        setopenSingle={setOpenTransfer}
        handleCancel={handleCancelTransfer}
      />
    </div>
  );
}

export default FundsTransferList;
