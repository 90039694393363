import React, { useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Tabs } from "antd";
import { useSelector } from "react-redux";
import moment from "moment";
import dayjs from 'dayjs'; 
import { numberWithCommas } from "../../utils";

export default function DashLineChart() {
  const { user } = useSelector((state) => state.auth);
  const { dashboardData } = useSelector((state) => state.fetch);
 
  const formatDateTime = (dateString) => {
    return dayjs(dateString)?.format('DD-MM-YYYY HH:mm:ss'); // Format as "20-01-2025 00:00:00"
  };
 
  const items = [
    {
      key: "1",
      label: (
        <span className="text-[14px] font-[600] max-w-full truncate">
          Total Monthly Collections
        </span>
      ),
      children: (
        <div>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart
              width={800}
              height={300}
              data={dashboardData?.monthlyCollections}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                // tickFormatter={formatDateTime}
                tickFormatter={(unixTime) => moment(unixTime).format("Do")}
              />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="collection"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <span className="text-[14px] font-[600]">
          Total Monthly Disbursements
        </span>
      ),
      children: (
        <div>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart
              width={800}
              height={300}
              data={dashboardData?.monthlyDisbursements}
              margin={{
                top: 5,
                right: 30,
                left: 40,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                tickFormatter={(unixTime) => moment(unixTime).format("Do")}
              />
              <YAxis  tickFormatter={numberWithCommas}  />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="disbursement"
                stroke="#82ca9d"
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      ),
    },
  ];

  useEffect(() => {}, [user, dashboardData?.monthlyDisbursements, dashboardData?.monthlyCollections]);

  return (
    <>
      <div className="flex flex-col border-2 border-[#EBEBEB] bg-white rounded-[14px] p-4 mt-10 lg:w-[80%] w-full">
        <div>
          <Tabs className="" defaultActiveKey="1" items={items} />
        </div>
      </div>
    </>
  );
}
