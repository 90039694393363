import { Dropdown, Input, Table } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import svg27 from "../../../assets/svg/svg27.svg";
import DeleteModal from "../../../components/DeleteModal";
import svg2 from "../../../assets/svg/svg2.svg";
import {
  fetchApprvNames, 
  fetchOtpDuration,
  fetchOtpVerification, 
} from "../../../features/fetch/fetchSlice";
import CardLoading from "../../../components/CardLoading"; 
import BreadCrumb from "../../../layout/BreadCrumb";
import Search from "../../../components/Search";
import AddOtpVerificationModal from "./modal/AddOtpVerificationModal";

export default function OtpVerificationTable() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {  loading, otpVerificationCount,otpVerificationData } = useSelector(
    (state) => state.fetch
  );

  const { deleteloading } = useSelector((state) => state.setting);

  const [searchText, setSearchText] = useState("");

  const [open, setopen] = useState(false);

  function handleCancel() {
    setopen(false);
  }

  const [prodd, setProdd] = useState("");
  const [isModalOpenDelete, setIsModalOpenDelete] = useState(false);
  const showModalDelete = async () => {
    setIsModalOpenDelete(true);
  };
 

  const handleAdd = async () => {
    await setProdd("");
    await setopen(true);
  };

  const handleEdit = () => {
    setopen(true);
  };

  const settingItems = [
    {
      key: "1",
      label: (
        <Link
          onClick={handleEdit}
          className="flex  text-[16px] font-sans  !text-lightBlack"
        >
          Edit
        </Link>
      ),
    },
    
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "otpProcessName",
      sorter: (a, b) => a.otpProcessName.localeCompare(b.otpProcessName),
    },
    {
      title: "Duration",
      dataIndex: "otpDuration",
      sorter: (a, b) => a.otpDuration.localeCompare(b.otpDuration),
    },
    {
      title: "Organization Name",
      dataIndex: "otpOrgName",
      sorter: (a, b) => a.otpOrgName.localeCompare(b.otpOrgName),
    },
    {
      title: "Created By",
      dataIndex: "otpCreatedByName",
      sorter: (a, b) => a.otpCreatedByName.localeCompare(b.otpCreatedByName),
    },
    {
      title: "Actions",
      render: (item) => (
        <>
          <Dropdown
            overlayStyle={{
              width: "250px",
            }}
            trigger={"click"}
            menu={{ items: settingItems }}
            placement="bottom"
          >
            <button onClick={() => setProdd(item)}>
              <img src={svg27} alt="svg27" />
            </button>
          </Dropdown>
        </>
      ),
    },
  ];

  const [searchInput, setsearchInput] = useState(null);

  const handleSearch = (e) => {
    setsearchInput(e.target.value);
    dispatch(
      fetchOtpVerification({
        url: "api/v2/otpVerification/fetchOtpVerification",
        otpProcessName: e.target.value == "" ? null : e.target.value,
      })
    );
  };

 
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  async function fetchOtpVerificationData(page, size) {
    dispatch(
      fetchOtpVerification({
        url: "api/v2/otpVerification/fetchOtpVerification",
        limit: size ?? pageSize,
        start: page ?? pageIndex,
      })
    );
  }
 
    async function fetchApprvNamesData(){
      await dispatch(fetchApprvNames())
    }
    async function fetchOtpDurationData(){
      await dispatch(fetchOtpDuration())
    }
   
  useEffect(() => {
    fetchOtpVerificationData();
    fetchApprvNamesData()
    fetchOtpDurationData()
  }, []);
  const breadList = [
    {
      title: (
        <div className="flex items-center gap-[.25rem]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <g clipPath="url(#clip0_627_13942)">
              <path
                d="M13.7899 6.89067L7.75241 0.856289L7.34772 0.451602C7.25551 0.360003 7.13082 0.308594 7.00085 0.308594C6.87087 0.308594 6.74618 0.360003 6.65397 0.451602L0.211783 6.89067C0.117301 6.98478 0.0426296 7.09687 -0.00782254 7.22031C-0.0582747 7.34376 -0.0834854 7.47607 -0.0819665 7.60942C-0.0757165 8.15942 0.382096 8.59848 0.932096 8.59848H1.59616V13.6875H12.4055V8.59848H13.0837C13.3508 8.59848 13.6024 8.49379 13.7915 8.30473C13.8846 8.21193 13.9583 8.10159 14.0085 7.98009C14.0586 7.8586 14.0842 7.72836 14.0837 7.59692C14.0837 7.33129 13.979 7.07973 13.7899 6.89067ZM7.87585 12.5625H6.12585V9.37504H7.87585V12.5625ZM11.2805 7.47348V12.5625H8.87585V9.00004C8.87585 8.65473 8.59616 8.37504 8.25085 8.37504H5.75085C5.40553 8.37504 5.12585 8.65473 5.12585 9.00004V12.5625H2.72116V7.47348H1.22116L7.00241 1.69691L7.36335 2.05785L12.7821 7.47348H11.2805Z"
                fill="black"
                fillOpacity="0.45"
              />
            </g>
            <defs>
              <clipPath id="clip0_627_13942">
                <rect width="14" height="14" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span>Home</span>
        </div>
      ),
      href: "/#/dashboard",
    },
    {
      title: <div className="text-lighGold text-[16px]">Otp Verification Setup</div>,
    },
  ];

  if (loading && !searchInput) return <CardLoading />;
  return (
    <div className="w-full h-full overflow-y-scroll">
      <div className="justify-between items-center white_card">
        <BreadCrumb
          breadList={breadList}
          header={"Otp Verification Information"}
          info={"Create your Otp Verification here"}
          btn={true}
          btnTitle="Add Otp Verification"
          onClick={handleAdd}
          btnSvg={svg2}
        />
      </div>

      <div className="bg-white rounded-[10px] mt-[1.25rem] h-full">
        <div className="flex flex-col w-full lg:px-10 px-3 ">
          <h3 className="heading_4 mt-[1.25rem]">Otp Verification Details</h3>
          <div className="mt-5 flex lg:flex-row flex-col gap-y-5 justify-between lg:items-center items-start w-full">
            <div className="w-[16.8125rem]">
              <Search
                searchInput={searchInput}
                handleSearch={handleSearch}
                text={"Search verfication name..."}
              />
            </div>

            <div className="flex lg:flex-row flex-col lg:items-center items-start lg:gap-x-10 gap-y-5"></div>
          </div>
          <div className="dash-inner-page mt-[24px] max-w-full w-full overflow-x-auto">
            <section>
              <Table
                rowSelection={false}
                className="mt-[1.31rem] w-full"
                scroll={{
                  x: 800,
                }}
                rowKey={(record) => record?.otpId}
                columns={columns}
                dataSource={otpVerificationData}
                loading={loading}
                pagination={{
                  position: ["bottomCenter"],
                  current: pageIndex + 1,
                  total: otpVerificationCount,
                  pageSize: pageSize,
                  onChange: (page, size) => {
                    setPageIndex(page - 1);
                    setPageSize(size);
                    fetchOtpVerificationData(page - 1, size);
                  },
                  showSizeChanger: false,
                  hideOnSinglePage: true,
                }}
              />
            </section>
          </div>
        </div>
      </div>

      {/* <DeleteModal
        isModalOpen={isModalOpenDelete}
        setIsModalOpen={setIsModalOpenDelete}
        prodd={prodd}
        handleDelete={handleDelete}
        loading={deleteloading}
        content={`Are you sure you want to delete  ${prodd?.otpProcessName} `}
        title={`Delete ${prodd?.otpProcessName}`}
      /> */}

      <AddOtpVerificationModal
        handleFetchData={fetchOtpVerificationData}
        open={open}
        handleCancel={handleCancel}
        prodd={prodd}
      />
    </div>
  );
}
