import React, { useEffect, useRef, useState } from "react";
import {
  Checkbox,
  Divider,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Spin,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { save } from "../../../features/save/saveSlice";
import { fetchMyUsers } from "../../../features/fetch/fetchSlice";

const SubwalletAddModal = ({
  isModalOpen,
  handleCancel,
  handleFetch,
  setMore,
}) => {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const { saving } = useSelector((state) => state.save);
  const { user } = useSelector((state) => state.auth); 
  const { loading, usersCount, myUsersData } = useSelector(
    (state) => state.fetch
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  async function fetchMyUsersData(page, size) {
    const res = await dispatch(
      fetchMyUsers({
        url: "api/v2/users/fetch-Identities",
      })
    );
  }
 

  const onFinish = async (data) => {
    if (!user?.disbursementWalUid) {
      toast.error("Login to get disbursment wallet data");
      return;
    }

    const res = await dispatch(
      save({
        url: `api/v1/wallet/createSubWallet`,
        walUsrId: data?.walUsrId,
        walId: user?.disbursementWalUid,
        walName: data?.walName,
      })
    );
    if (res?.payload?.success) {
      await toast.success(res?.payload?.messages?.message);
      await handleCancel();
      await handleFetch();
      await setMore(true);
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  };

  return (
    <>
      <Modal
        className=""
        title={"Add Subwallet"}
        open={isModalOpen}
        onOk={onFinish}
        onCancel={handleCancel}
        width={800}
      >
        <Form
          layout="vertical"
          ref={formRef}
          name="control-ref"
          onFinish={onFinish}
          className="px-[15px]"
          style={{
            maxWidth: "100%",
          }}
          form={form}
        >
          <Form.Item
            name="walName"
            label="Subwallet Name"
            rules={[
              {
                required: true,
                message: "Please add sub wallet name",
              },
            ]}
          >
            <Input className="input" />
          </Form.Item>

          <Form.Item
            extra={"You can assign more than one approved user"}
            name="walUsrId"
            rules={[
              {
                required: true,
                message: "Required field",
              },
            ]}
          >
            <Select
              mode="multiple"
              allowClear
              showSearch
              style={{ height: "52px" }}
              size="large"
              className=""
              dropdownRender={(menu) => (
                <>
                  <Space style={{ padding: "8px 8px 4px" }}>
                    <span className="select-info mt-1">
                      Choose one or more of the following approved users
                    </span>
                  </Space>
                  <Divider style={{ margin: "8px 0" }} />
                  {menu}
                </>
              )}
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.children?.toLowerCase() ?? "").includes(
                  input.toLowerCase()
                )
              }
              onDropdownVisibleChange={() => {
                fetchMyUsersData(); 
              }}
            >
              {myUsersData?.length > 0 &&
                myUsersData
                  .filter(
                    (user) =>
                      user?.usrId && 
                      user?.usrFirstname && 
                      user?.usrLastname &&
                      user?.usrFirstname !== "string" && 
                      user?.usrLastname !== "string"
                  )
                  .map((user) => (
                    <Select.Option key={user?.usrId} value={user?.usrId}>
                      {`${user?.usrFirstname} ${user?.usrLastname}`}
                    </Select.Option>
                  ))}
            </Select>
          </Form.Item>

          <div className="flex justify-between mt-[48px] ">
            <div className="justify-start"></div>
            <div className="justify-end flex items-center mb-[58px] gap-x-5">
              <div className="w-[150px] ">
                <button
                  type="button"
                  key="back"
                  onClick={handleCancel}
                  className="cstm-btn"
                >
                  Cancel
                </button>
              </div>

              <div className="w-[150px]">
                <button key="submit" type="submit" className="cstm-btn-2">
                  {saving ? <Spin /> : "Create"}
                </button>
              </div>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};
export default SubwalletAddModal;
