import { DatePicker, Form, Input, Select, Spin } from "antd";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { counties } from "../../../counties";
import {
  fetchattachmentLevel2,
  fetchattachmentRequirements,
} from "../../../features/fetch/fetchSlice";
import { transactionLimitData } from "../../../data";

export default function OrganizationDetailsStep({ handleNext, form }) {
  const dispatch = useDispatch();

  const { authLoading, authData } = useSelector((state) => state.auth);
  const { attRequirements, attLevel2 } = useSelector((state) => state.fetch);
  const { orgObj } = useSelector((state) => state.obj);

  const [showLevel2, setShowLevel2] = useState(false);
  const [showLevel3, setShowLevel3] = useState(false);

  const [open, setopen] = useState(false);
  const [kraFile, setkraFile] = useState(authData?.usrKraPinFile ?? "");

  const today = new Date();
  let dd = String(today.getDate()).padStart(2, 0);
  let mm = String(today.getMonth() + 1).padStart(2, 0);
  let yyyy = today.getFullYear() - 18;

  let minDate = `${yyyy}-${mm}-${dd}`;

  function handleCancel() {
    setopen(false);
  }

  async function handleSubmitStep() {
    try {
      await form.validateFields([
        "orgName",
        "orgEmail",
        "orgMobile",
        "orgCounty",
        "orgKraPin",
        "orgTown",
        "orgLocation",
        "orgBizTypeId",
        "orgDailyLimit",
        "orgRegistrationDate",
      ]);
      await handleNext();
    } catch {}
  }

  async function fetchattachmentRequirementsData() {
    await dispatch(
      fetchattachmentRequirements({
        url: `api/v1/onboard/get-attachment-requirements`,
        attrCat: "BUSINESS_TYPES",
      })
    );
  }

  async function fetchTier(id) {
    await dispatch(
      fetchattachmentLevel2({
        url: `api/v1/onboard/get-attachment-requirements`,
        attrParentId: id,
        attrName: "TIER",
      })
    );
  }
  return (
    <>
      <div className="flex flex-col w-full  h-full ">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-[.5rem]">
          <Form.Item
            label="Organization Name"
            name="orgName"
            rules={[
              {
                required: true,
                message: "Organization name is required",
              },
            ]}
          >
            <Input className="input" />
          </Form.Item>

          <Form.Item
            name="orgEmail"
            label="Organization Email"
            rules={[
              {
                required: true,
                message: "Organization Email is required to login",
              },
              {
                type: "email",
                message: "Please enter a valid organization email address",
              },
            ]}
          >
            <Input type="email" className="input" />
          </Form.Item>

          <Form.Item
            label="Organization Phone"
            name="orgMobile"
            validateFirst
            rules={[
              {
                required: true,
                message: "Phone number is required",
              },
              {
                validator: (_, value) => {
                  if (value && value.length >= 12) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "Phone number must be exactly 10 characters excluding country code"
                    )
                  );
                },
              },
            ]}
          >
            <PhoneInput
              enableSearch
              country="ke"
              countryCodeEditable={false}
              className="input"
            />
          </Form.Item>

          <Form.Item
            name="orgCounty"
            className=""
            label={<span>Select County</span>}
            rules={[
              {
                required: true,
                message: "Please select sender name",
              },
            ]}
          >
            <Select
              className=""
              allowClear
              style={{
                width: "100%",
              }}
              options={
                counties?.length > 0 &&
                counties?.map((item) => ({
                  value: item?.name,
                  label: item?.name,
                }))
              }
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label?.toLocaleLowerCase() ?? "").includes(
                  input?.toLocaleLowerCase()
                )
              }
              onDropdownVisibleChange={() => {}}
            />
          </Form.Item>
          <Form.Item
            name="orgRegNo"
            label="Business Registration No."
            rules={[
              {
                required: true,
                message: "Organization Email is required to login",
              },
            ]}
          >
            <Input className="input" />
          </Form.Item>
          <Form.Item
            name="orgRegistrationDate"
            rules={[
              {
                required: false,
                message: "Field is required",
              },
            ]}
            label="Registration Date"
          >
            <DatePicker format={"YYYY MMM DD"} className="input" />
          </Form.Item>

          <Form.Item
            className="table-form-item"
            name="orgKraPin"
            label="KRA Pin"
            rules={[
              {
                required: true,
                message: "Please add KRA pin",
              },
              {
                pattern: /^[A-Z0-9]{11}$/,
                message:
                  "Invalid Kra Pin format. A valid Kra Pin should be 11 alphanumeric characters.",
              },
            ]}
          >
            <Input className="input" />
          </Form.Item>

          <Form.Item
            name="orgTown"
            label="Town"
            rules={[
              {
                required: true,
                message: "Town is required to login",
              },
            ]}
          >
            <Input className="input" />
          </Form.Item>

          <Form.Item
            name="orgPhysicalAdr"
            label="Physical Address"
            rules={[
              {
                required: true,
                message: "Physical Address is required to login",
              },
            ]}
          >
            <Input className="input" />
          </Form.Item>

          <Form.Item
            name="orgBizTypeId"
            className=""
            label={"Business Type"}
            rules={[
              {
                required: true,
                message: "Please select Business Types",
              },
            ]}
          >
            <Select
              className=""
              allowClear
              style={{
                width: "100%",
              }}
              options={
                attRequirements?.length > 0 &&
                attRequirements?.map((item) => ({
                  value: item?.attrId,
                  label: item?.attrName,
                }))
              }
              onChange={async (value) => {
                setShowLevel2(true);
                await fetchTier(value);
                form.setFieldsValue({ orgBizTierId: undefined });
              }}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label?.toLocaleLowerCase() ?? "").includes(
                  input?.toLocaleLowerCase()
                )
              }
              onDropdownVisibleChange={() => {
                fetchattachmentRequirementsData();
              }}
            />
          </Form.Item>

          <Form.Item
            name="orgDailyLimit"
            className=""
            label={"Transaction Limit"}
            rules={[
              {
                required: true,
                message: "Please select transaction limit",
              },
            ]}
          >
            <Select
              className=""
              allowClear
              style={{
                width: "100%",
              }}
              options={
                transactionLimitData?.length > 0 &&
                transactionLimitData?.map((item) => ({
                  value: item?.value,
                  label: item?.label,
                }))
              }
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label?.toLocaleLowerCase() ?? "").includes(
                  input?.toLocaleLowerCase()
                )
              }
            />
          </Form.Item>

          {/* <Form.Item
            name="orgBizTierId"
            className=""
            label={"Business Tier"}
            rules={[
              {
                required: true,
                message: "Please select Business Tier",
              },
            ]}
          >
            <Select
              className=""
              allowClear
              style={{
                width: "100%",
              }}
              options={
                attLevel2?.length > 0 &&
                attLevel2?.map((item) => ({
                  value: item?.attrId,
                  label: item?.attrName,
                }))
              }
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label?.toLocaleLowerCase() ?? "").includes(
                  input?.toLocaleLowerCase()
                )
              }
              onDropdownVisibleChange={() => {}}
            />
          </Form.Item> */}
        </div>

        <div className="py-7 w-full flex justify-center">
          <button
            disabled={authLoading}
            onClick={handleSubmitStep}
            type="button"
            className="cstm-btn-2 !w-[200px]"
          >
            {authLoading ? <Spin /> : "Next"}
          </button>
        </div>
      </div>
    </>
  );
}
