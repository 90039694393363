import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Search from "../../../components/Search";
import { Checkbox, Form } from "antd";
import { fetchPermissions } from "../../../features/fetch/fetchSlice";
import EmtyCard from "../../../components/EmtyCard";
import CardLoading from "../../../components/CardLoading";
import toast from "react-hot-toast";
import { save } from "../../../features/save/saveSlice";

function Permissions() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const formRef = useRef(null);

  const { activeObj } = useSelector((state) => state.obj);
  const { permissionData,loading } = useSelector((state) => state.fetch);
  const [searchInput, setsearchInput] = useState("");
  const { refetchKey } = useSelector((state) => state.global);

  const [permissionValue, setPermissionValue] = useState([]);

  const onChange = async(checkedValues) => {
    await setPermissionValue(checkedValues);
    onFinish(checkedValues)
  };

  const handleSearch = (val) => {
    setsearchInput(val);
  };
  // {
  //   "permissionValue": ["ADMIN_UPDATE", "CREATE_TEMPLATE"]
  // }
    const onFinish = async (data) => {
      const res = await dispatch(
        save({
          url: `api/v2/users/${activeObj?.usrId}/permissions`,
          permissionValue:data,
        })
      );
      // permission:permissionValue?.join(","), 
      if (res?.payload?.success) {
        await toast.success(res?.payload?.messages?.message);
        // await setPermissionValue(res?.payload?.data?.result?.permissions)
        // await fetchPermissionsData(); 
      } else {
        toast.error(res?.payload?.messages?.message);
      }
    };
  // const permissionData = {
  //     permission: [
  //         { label: 'Communication', value: 'view' },
  //         { label: 'Edit All', value: 'edit' },
  //         { label: 'Delete All', value: 'delete' },
  //     ],
  // };

  async function fetchPermissionsData() {
    await dispatch(
      fetchPermissions({
        role: activeObj?.role,
      })
    );
  } 
  useEffect(() => {
    if (activeObj?.permissions) {
      setPermissionValue(activeObj.permissions);
    }
  }, [activeObj]);

  useEffect(() => {
    if (refetchKey == 3) {
      fetchPermissionsData();
    }
  }, [refetchKey]);
 
  if (loading) return <CardLoading />;
  return (
    <div className="white_card flex flex-col">
      <div className="heading_4">{activeObj?.usrFirstname}’s Permissions ({permissionData?.name})</div>
      {permissionData && permissionData?.permission?.length > 0 ? (
        <>
          {" "}
          <div className="permission-card p-[1rem]  lg:w-[50%] w-full">
            <div className="typography-permission mt-[1.75rem] ">
              Assign permissions to  {activeObj?.usrFirstname}{" "}
              {activeObj?.usrLastname}
            </div>
            <div className="w-full mt-[.19rem]">
              <Search
                text={"Search permission or keyword"}
                searchInput={searchInput}
                handleSearch={handleSearch}
              />
            </div>
            <div className="mt-[1.5rem]">
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please select a permission",
                  },
                ]}
              >
                <Checkbox.Group
                  className="filter_checkbox flex flex-col gap-y-2"
                  options={permissionData?.permission}
                  value={permissionValue}
                  onChange={onChange}
                />
              </Form.Item>
            </div>
          </div>
        </>
      ) : (
        <EmtyCard title="No permissions availabe" />
      )}
    </div>
  );
}

export default Permissions;
