import { Dropdown, Input, Table } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import svg27 from "../../assets/svg/svg27.svg";
import NoDataTable from "../../components/NoDataTable";
import FilterModal from "./modal/FilterModal";
import svg38 from "../../assets/svg/svg38.svg";
import FunnelSimple from "../../assets/svg/FunnelSimple.svg";
import useModalToggle from "../../custom_hooks/useModalToggle";
import BreadCrumb from "../../layout/BreadCrumb";
import flash from "../../assets/svg/Flash.svg";
import { capitalize } from "../../utils";
import { fetchMyUsers } from "../../features/fetch/fetchSlice";
import UserPermissionModal from "./modal/UserPermissionModal";
import { clearObj, setActiveObj } from "../../features/obj/objSlice";
import svg32 from "../../assets/svg/svg32.svg";
import eyeSvg from "../../assets/svg/eye.svg";
import CardLoading from "../../components/CardLoading";
import Search from "../../components/Search";

export default function UsersList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { open, handleCancel, handleOpen } = useModalToggle();

  const [prodd, setProdd] = useState();

  const [openUser, setOpenUser] = useState(false);
  const handleAddUser = async () => {
    await dispatch(clearObj());
    await setProdd("");
    await navigate("/users-add-list");
    // await setOpenUser(true);
  };
  const handleCancelUser = () => {
    setOpenUser(false);
  };

  const [searchInput, setsearchInput] = useState(null);

  const handleSearch = (e) => {
    setsearchInput(e.target.value);
    dispatch(
      fetchMyUsers({
        url: "api/v2/users/fetch-Identities",
        usrFirstname: e.target.value == "" ? null : e.target.value,
      })
    );
  };

  const handleView = async (item) => {
    await dispatch(setActiveObj(item));
    await navigate("/users-add-list");
  };

  const [openPermission, setOpenPermission] = useState(false);
  const handleOpenPermission = () => {
    setOpenPermission(true);
  };
  const handleCancelPermission = () => {
    setOpenPermission(false);
  };

  const { loading, usersCount, myUsersData,dropLoading } = useSelector(
    (state) => state.fetch
  );
  const { walletObj } = useSelector((state) => state.obj);

  const [searchText, setSearchText] = useState("");

  const [filters, setFilters] = useState([]);
  const [formData, setFormData] = useState({});
  const showModalFilter = () => {
    handleOpen();
  };

  const handleClearFilters = async () => {
    await setFormData({});
    await setFilters([]);
  };

  const settingItems = [
    {
      key: "1",
      label: (
        <Link
          onClick={handleView}
          className="flex  text-[16px] font-sans  !text-lightBlack"
        >
          View
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <Link
          onClick={handleOpenPermission}
          className="flex  text-[16px] font-sans  !text-lightBlack"
        >
          Assign Permission
        </Link>
      ),
    },
  ];

  const columns = [
    {
      title: "First Name",
      dataIndex: "usrFirstname",
      sorter: (a, b) => a.usrFirstname.localeCompare(b.usrFirstname),
    },
    {
      title: "Last Name",
      dataIndex: "usrLastname",
      sorter: (a, b) => a.usrLastname.localeCompare(b.usrLastname),
    },

    {
      title: "Email Address",
      dataIndex: "usrEmail",
      sorter: (a, b) => a.usrEmail.localeCompare(b.usrEmail),
    },
    {
      title: "Mobile Number",
      dataIndex: "usrMobileNumber",
      sorter: (a, b) => a.usrMobileNumber.localeCompare(b.usrMobileNumber),
    },
    {
      title: "National ID",
      dataIndex: "usrNationalId",
      sorter: (a, b) => a.usrNationalId.localeCompare(b.usrNationalId),
    },
    {
      title: "Status",
      render: (item) => {
        return (
          <div className="flex items-center text-center gap-x-2">
            <span
              className={`w-[10px] h-[10px] rounded-full ${
                item?.usrStatus === "ACTIVE" ? "bg-green" : "bg-red"
              }`}
            ></span>
            {item?.usrStatus}
          </div>
        );
      },
      sorter: (a, b) => a.usrStatus.localeCompare(b.usrStatus),
    },
    {
      title: "Role",
      dataIndex: "role",
      sorter: (a, b) => a.role.localeCompare(b.role),
    },
    {
      title: "View",
      render: (item) => (
        <button onClick={() => handleView(item)}>
          <img src={eyeSvg} alt="eyeSvg" />
        </button>
      ),
    },
  ];
  const breadList = [
    {
      title: (
        <div className="flex items-center gap-[.25rem]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <g clipPath="url(#clip0_627_13942)">
              <path
                d="M13.7899 6.89067L7.75241 0.856289L7.34772 0.451602C7.25551 0.360003 7.13082 0.308594 7.00085 0.308594C6.87087 0.308594 6.74618 0.360003 6.65397 0.451602L0.211783 6.89067C0.117301 6.98478 0.0426296 7.09687 -0.00782254 7.22031C-0.0582747 7.34376 -0.0834854 7.47607 -0.0819665 7.60942C-0.0757165 8.15942 0.382096 8.59848 0.932096 8.59848H1.59616V13.6875H12.4055V8.59848H13.0837C13.3508 8.59848 13.6024 8.49379 13.7915 8.30473C13.8846 8.21193 13.9583 8.10159 14.0085 7.98009C14.0586 7.8586 14.0842 7.72836 14.0837 7.59692C14.0837 7.33129 13.979 7.07973 13.7899 6.89067ZM7.87585 12.5625H6.12585V9.37504H7.87585V12.5625ZM11.2805 7.47348V12.5625H8.87585V9.00004C8.87585 8.65473 8.59616 8.37504 8.25085 8.37504H5.75085C5.40553 8.37504 5.12585 8.65473 5.12585 9.00004V12.5625H2.72116V7.47348H1.22116L7.00241 1.69691L7.36335 2.05785L12.7821 7.47348H11.2805Z"
                fill="black"
                fillOpacity="0.45"
              />
            </g>
            <defs>
              <clipPath id="clip0_627_13942">
                <rect width="14" height="14" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span>Home</span>
        </div>
      ),
      href: "/#/home",
    },
    {
      title: capitalize("Users"),
    },
  ];

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  async function fetchMyUsersData(page, size) {
    const res = await dispatch(
      fetchMyUsers({
        url: "api/v2/users/fetch-Identities",
        limit: size ?? pageSize,
        start: page ?? pageIndex,
        usrFirstname: formData?.usrFirstname,
        usrNationalId: formData?.usrNationalId,
        usrMobileNumber: formData?.usrMobileNumber,
        usrStatus: formData?.usrStatus,
      })
    );
  }
  useEffect(() => {
    fetchMyUsersData();
  }, []);
  if (loading && !searchInput) return <CardLoading />;
  return (
    <>
      <div className="flex flex-col w-full h-full overflow-y-scroll">
        <div className="">
          <div className="justify-between items-center white_card">
            <BreadCrumb
              breadList={breadList}
              header={"List of Users"}
              info={"Manage your users here"}
              btn={true}
              btnTitle="Add User"
              btnSvg={flash}
              onClick={handleAddUser}
            />
          </div>
       
            <div className="bg-white rounded-[10px] mt-[1.25rem] h-full">
              <div className="flex flex-col w-full lg:px-10 px-3 ">
              <div className="w-[16.8125rem] mt-[1.25rem]">
              <Search
                searchInput={searchInput}
                handleSearch={handleSearch}
                text={"Search user..."}
              />
            </div>
                
                <div className="dash-inner-page mt-[24px] max-w-full w-full overflow-x-auto">
                  <section>
                    <Table
                      rowSelection={false}
                      className="mt-[1.31rem] w-full"
                      scroll={{
                        x: 1200,
                      }}
                      rowKey={(record) => record?.custId}
                      columns={columns}
                      dataSource={myUsersData}
                      loading={dropLoading}
                      pagination={{
                        position: ["bottomCenter"],
                        current: pageIndex + 1,
                        total: usersCount,
                        pageSize: pageSize,
                        onChange: (page, size) => {
                          setPageIndex(page - 1);
                          setPageSize(size);
                          fetchMyUsersData(page - 1, size);
                        },
                        showSizeChanger: false,
                        hideOnSinglePage: true,
                      }}
                    />
                  </section>
                </div>
              </div>
            </div>
         
        </div>
      </div>
      <FilterModal
        isModalOpen={open}
        handleCancel={handleCancel}
        formData={formData}
        setFormData={setFormData}
        filters={filters}
        setFilters={setFilters}
      />

      <UserPermissionModal
        isModalOpen={openPermission}
        handleCancel={handleCancelPermission}
        prodd={prodd}
      />
    </>
  );
}
