import React, { useEffect, useRef, useState } from "react";
import { Form, Input, InputNumber, Modal, Select, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { save } from "../../../../features/save/saveSlice";

const { TextArea } = Input;
const AddUtilityModal = ({ open, handleCancel, handleFetchData, prodd }) => {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { saving } = useSelector((state) => state.save);
 
  const onFinish = async (data) => {
    data.utilId = prodd.utilId ?? null;
    const res = await dispatch(
      save({
        ...data,

        url: "api/v2/utility/createUtility",
      })
    );
    if (res?.payload?.success) {
      await toast.success(res?.payload?.messages?.message);
      await handleFetchData();
      await handleCancel();
      await form.resetFields();
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  };

  useEffect(() => {
    if (prodd) {
      form.setFieldsValue(prodd);
    } else {
      form.resetFields();
    }
  }, [prodd, form]);

  return (
    <>
      <Modal
        className="modal-btn mod-height mod-footer"
        title={prodd ? `Edit ${prodd?.utilType}` : "Add Utility Details"}
        open={open}
        onOk={onFinish}
        onCancel={handleCancel}
        width={850}
      >
        <Form
          layout="vertical"
          ref={formRef}
          name="control-ref"
          onFinish={onFinish}
          className=" "
          style={{
            maxWidth: "100%",
          }}
          form={form}
          // initialValues={prodd}
        >
          <Form.Item
            name="utilType"
            className=""
            label={<span>Utility Name</span>}
            rules={[
              {
                required: true,
                message: "Please add utility name",
              },
            ]}
          >
            <Input
              className="input"  />
          </Form.Item>
 
          <Form.Item
            name="utilDesc"
            label="Description"
            rules={[
              {
                required: true,
                message: "Enter description",
              },
            ]}
          >
            <TextArea
              rows={4}
              placeholder="Enter description"
              className="input-textarea"
            />
          </Form.Item>

          <div className="flex lg:flex-row flex-col justify-between mt-[56px] mb-[48px]">
            <div className="justify-start"></div>
            <div className="justify-end flex items-center gap-x-2">
              <div className="w-[200px]">
                <button
                  key="back"
                  type="button"
                  onClick={handleCancel}
                  className="cstm-btn"
                >
                  Cancel
                </button>
              </div>

              <div className="w-[200px]">
                <button
                  key="submit"
                  type="submit"
                  className="cstm-btn-2"
                  disabled={saving}
                >
                  {saving ? <Spin /> : "Create"}
                </button>
              </div>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};
export default AddUtilityModal;
