import React, { useRef, useState } from "react";
import userImg from "../../assets/img/user.png";
import PhoneInput from "react-phone-input-2";
import { DatePicker, Form, Input, InputNumber, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import uplodSvg from "../../assets/img/uploadPic.png";
import { formatImgPath } from "../../utils";
import dayjs from "dayjs";

function AdminDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { saving } = useSelector((state) => state.save);
  const [form] = Form.useForm();
  const formRef = useRef(null);

  const today = new Date();
  let dd = String(today.getDate()).padStart(2, 0);
  let mm = String(today.getMonth() + 1).padStart(2, 0);
  let yyyy = today.getFullYear() - 18;

  let minDate = `${yyyy}-${mm}-${dd}`;

  const [profile, setProfile] = useState();
  const [loadingAttach, setLoadingAttach] = useState(false);

  const handleImageSelect = async (event) => {
    // setLoadingAttach(true);
    // const formData = new FormData();
    // formData.append("file", event.target.files[0]);
    // const res = await dispatch(saveFile(formData));
    // if (res?.payload?.success) {
    //   setLoadingAttach(false);
    //   setProfile(res?.payload?.jsonData ?? "");
    // } else {
    //   setLoadingAttach(false);
    //   toast.error("Please try upload your image again");
    // }
  };
  const onFinish = () => {};
  const handleBack = () => {
    navigate(-1);
  };
  return (
    <div className="flex items-center flex-col px-10 w-full">
      <div className="flex flex-col items-center justify-center gap-y-1 w-full">
        <div className="items-center flex gap-5 ">
          {!profile ? (
            <label className="flex flex-row items-center justify-start w-[90%]">
              <input
                name="secIcon"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={(e) => handleImageSelect(e)}
              />
              <img
                loading="lazy"
                className=" w-[100px] h-[100px] rounded-full"
                alt="tab1"
                src={uplodSvg}
                style={{ cursor: "pointer" }}
              />
            </label>
          ) : loadingAttach ? (
            <Spin className="spin-modal" />
          ) : (
            <label className="flex flex-row items-center justify-start gap-2  w-[20%]">
              <input
                name="secIcon"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleImageSelect}
              />
              <div className="rounded-full  overflow-hidden border-4 border-blueDark">
                <img
                  src={formatImgPath(profile)}
                  alt="director"
                  className="w-28 h-28 cursor-pointer object-cover"
                />
              </div>
            </label>
          )}
        </div>
        <span className="heading_1 mt-5"> Job kinyua</span>
        <span className="member_info_text !font-normal">
          jobkinyua21@gmail.com
        </span>
        <span className="member_info_text !font-normal">+254 715 737 532</span>
      </div>
      <div className="w-full mt-10">
        {" "}
        <Form
          layout="vertical"
          ref={formRef}
          name="newUserInvite"
          onFinish={onFinish}
          style={{
            maxWidth: "100%",
            width: "100%",
          }}
          form={form}
        >
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
            <Form.Item
              label="Administrator First Name"
              name="usrFirstName"
              rules={[
                {
                  required: true,
                  message: "Administrator first name is required",
                },
              ]}
            >
              <Input className="input" />
            </Form.Item>
            <Form.Item
              label="Administrator Last Name"
              name="usrLastName"
              rules={[
                {
                  required: true,
                  message: "Administrator Last name is required",
                },
              ]}
            >
              <Input className="input" />
            </Form.Item>
            <Form.Item
              name="usrEmail"
              label="Admin Email"
              rules={[
                {
                  required: true,
                  message: "Admin Email is required to login",
                },
                {
                  type: "email",
                  message: "Please enter a valid Admin email address",
                },
              ]}
            >
              <Input type="email" className="input" />
            </Form.Item>

            <Form.Item
              name="usrNationalId"
              label="National ID"
              rules={[
                {
                  required: true,
                  message: "Admin National ID is required",
                },
                {
                  validator(_, value) {
                    if (value && value.toString().length < 6) {
                      return Promise.reject(
                        "National ID must be at least 6 characters long"
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <InputNumber
                min={0}
                parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                className="input flex"
              />
            </Form.Item>
            <Form.Item
              name="usrDob"
              rules={[
                {
                  required: false,
                  message: "Field is required",
                },
              ]}
              label="Date of Birth"
            >
              <DatePicker
                maxDate={dayjs(minDate)}
                format={"YYYY MMM DD"}
                className="input"
              />
            </Form.Item>

            <Form.Item
              label="Admin Phone"
              name="usrMobileNumber"
              validateFirst
              rules={[
                {
                  required: true,
                  message: "Admin phone number is required",
                },
                {
                  validator: (_, value) => {
                    if (value && value.length >= 12) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "Phone number must be exactly 10 characters excluding country code"
                      )
                    );
                  },
                },
              ]}
            >
              <PhoneInput
                enableSearch
                country="ke"
                countryCodeEditable={false}
                className="input"
              />
            </Form.Item>
          </div>

          <div className="flex items-center justify-end mt-[5rem] w-full">
            <div className="w-[12.4375rem] flex items-center gap-[.5rem]">
              <button
                disabled={saving}
                onClick={handleBack}
                className="modal_btn_light"
                type="button"
              >
                Cancel
              </button>

              <button
                disabled={saving}
                className="modal_btn_dark"
                type="submit"
              >
                {saving ? <Spin /> : "Update"}
              </button>
            </div>
          </div>
        </Form>{" "}
      </div>
    </div>
  );
}

export default AdminDetails;
