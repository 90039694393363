import React, { useEffect } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import EmtyCard from "../../components/EmtyCard";
import { cashConverter } from "../../utils";

function DashRecentTransactions({ disb }) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
    const { dashboardData } = useSelector((state) => state.fetch);
   

  const paySummary = dashboardData?.recentDisbursements;
  const colReceipts = dashboardData?.recentCollections;

  async function handleFetch() {}

  useEffect(() => {
    handleFetch();
  }, [user]);

  useEffect(() => {}, [user, colReceipts, paySummary]);

  return (
    <>
      <div className="mt-10 border-2 !border-[#EBEBEB] rounded-[14px] !bg-white p-2 w-full">
        <div className="border-b text-center p-2 mb-3">
          <span className="text-[#0D163A] text-[18px] font-[600]">
            Recent {disb ? "Disbursements" : "Incoming Payments"}
          </span>
        </div>

        {disb ? (
          <>
            {paySummary?.length > 0 ? (
              <>
                {paySummary?.slice(0, 3)?.map((item) => {
                  return (
                    <>
                      <div className="flex justify-between items-center px-3 py-4 text-gray-900 whitespace-nowrap">
                        <div className="flex items-center">
                          <img
                            className="w-[48px] h-[48px] rounded-full"
                            src="https://w7.pngwing.com/pngs/81/570/png-transparent-profile-logo-computer-icons-user-user-blue-heroes-logo-thumbnail.png"
                            alt="transaction"
                          />
                          <div className="pl-3">
                            <div className="text-[#0D163A] text-[18px] font-[600]">
                              {item?.blkBeneficiaryAccount}
                            </div>
                            <div className="text-[#AFAFAF] text-[12px] font-[600]">
                              {moment(item?.blkPaymentDate).format(
                                "MMM DD YYYY, h:mm A"
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="pl-3">
                          <div className="text-[#0D163A] text-[18px] font-[600]">
                              {cashConverter(item?.blkPaymentAmount)}
                          </div>
                          <div className="text-[#3BBB6E] text-[12px] font-[600]">
                            {item?.blkStatus}
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </>
            ) : (
              <EmtyCard title="No Recent Disbursement Yet" />
            )}
          </>
        ) : (
          <>
            {colReceipts?.length > 0 ? (
              <>
                {colReceipts?.slice(0, 3)?.map((item) => {
                  return (
                    <>
                      <div className="flex justify-between items-center px-3 py-4 text-gray-900 whitespace-nowrap">
                        <div className="flex items-center">
                          <img
                            className="w-[48px] h-[48px] rounded-full"
                            src="https://w7.pngwing.com/pngs/81/570/png-transparent-profile-logo-computer-icons-user-user-blue-heroes-logo-thumbnail.png"
                            alt="transaction"
                          />
                          <div className="pl-3">
                            <div className="text-[#0D163A] text-[18px] font-[600]">
                              {item?.msisdn}
                            </div>
                            <div className="text-[#AFAFAF] text-[12px] font-[600]">
                              {moment(item?.payCreatedDate).format(
                                "MMM DD YYYY, h:mm A"
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="pl-3">
                          <div className="text-[#0D163A] text-[18px] font-[600]">
                             {cashConverter(item?.transAmount)}
                          </div>
                          <div className="text-[#3BBB6E] text-[12px] font-[600]">
                          {item?.requestStatus}
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </>
            ) : (
              <EmtyCard title="No Incoming Payments Yet" />
            )}
          </>
        )}
      </div>
    </>
  );
}

export default DashRecentTransactions;
