import { Descriptions, Form, Input, Modal, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"; 
import toast from "react-hot-toast";
import { save } from "../../../../features/save/saveSlice";
import { cashConverter } from "../../../../utils";

function OtpModal({  open, handleCancel, handleFetchData,handleCancelAll }) {
  const { saving } = useSelector((state) => state.save);
  const { singlePaymentObj,activeObj,memberObj } = useSelector((state) => state.obj);
  const { grpTotals } = useSelector((state) => state.fetch);
  const { user } = useSelector((state) => state.auth);
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({});
  const [isInputEmpty, setIsInputEmpty] = useState(true);

  const [verificationCode, setVerificationCode] = useState(["", "", "", ""]);

  const [counter, setCounter] = useState({ minutes: 0, seconds: 59 });
  const [activeResend, setActiveResend] = useState(false);

  const cancatenatedString = verificationCode?.join("");
 

  const onFinish = async (data) => { 
    const res = await dispatch(
        save({
            usrSecret: cancatenatedString,
            url: 'api/v1/payments/verify-payment-otp',
        })
    );
    
    if (res?.payload?.success) {
        await toast.success(res.payload?.messages?.message);
        const { paymentSourceName, ...restOfData } = singlePaymentObj;
        const memberId = memberObj?.length > 0 ? memberObj?.map((item) => item?.pgdId)  : [];
        const res1 = await dispatch(
            save({
                ...restOfData,
                pgdId: memberId,
                url: `api/v1/payments/disburse-payment-group/${activeObj?.pgId}`,
            })
        );
        if (res1?.payload?.success) {
            await toast.success(res.payload?.messages?.message);
            await handleCancelAll(); 
            // await handleFetchData();
            await navigate("/group-payment")      
            } else {
            toast.error(res.payload?.messages?.message);
        }
        await handleFetchData(); 
        await setIsInputEmpty(true);
        await setVerificationCode(["", "", "", ""])
        await form.resetFields();
        await handleCancelAll()
    } else {
        toast.error(res.payload?.messages?.message);
    }
};
 
 
  const handleInputChange = (e, index) => {
    const { value } = e.target;
    setIsInputEmpty(!value);
    if (value === "") {
      if (index > 0) {
        inputRefs[index - 1].current.focus();
      }
    } else if (index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }

    const updatedVerificationCode = [...verificationCode];
    updatedVerificationCode[index] = value;
    setVerificationCode(updatedVerificationCode);
  };

 

  return (
    <>
      <Modal
        className="modal-btn mod-height mod-footer"
        title={`Verification of ${cashConverter(grpTotals)} `}
        open={open}
        onOk={onFinish}
        onCancel={handleCancel}
        width={750}
        maskClosable={false}
      >
        <div className="w-full h-full flex flex-col">
         
          <div className="heading_5">
            We have sent an OTP to the registered phone number 
          </div>

          <Form
            layout="vertical"
            ref={formRef}
            name="control-ref"
            onFinish={onFinish}
            style={{
              maxWidth: "100%",
            }}
            className="lg:px-20 px-3"
            form={form}
          >
            <div className="flex items-center justify-center gap-[1.25rem] mt-[1.25rem]">
              {inputRefs?.map((ref, index) => (
                <div key={index}>
                  <input
                    ref={ref}
                    className="otp-input"
                    type="text"
                    maxLength="1"
                    value={verificationCode[index]}
                    onChange={(e) => handleInputChange(e, index)}
                  />
                </div>
              ))}
            </div>
            

            <div className="justify-center flex items-center gap-x-2 mt-[56px] mb-[48px]">
              <div className="w-[150px]">
                <button
                  key="back"
                  type="button"
                  onClick={handleCancel}
                  className="cstm-btn"
                >
                  Previous
                </button>
              </div>

              <div className="w-[150px]">
                <button
                  type="submit"
                  key="submit"
                  className={`cstm-btn-2 ${
                    isInputEmpty ? "!bg-[#D1D5DB]" : "inherit"
                  } ${isInputEmpty ? "cursor-not-allowed" : "inherit"}`}
                  disabled={isInputEmpty}
                >
                  {saving ? <Spin /> : "Transfer"}
                </button>
              </div>
            </div>

          </Form>
        </div>
      </Modal>
    </>
  );
}


export default OtpModal;

