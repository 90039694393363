import { DatePicker, Form, Input, Select, Spin } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import citiesData from "../../../countries.json";
import { addressTypeData } from "../../../data";

export default function AddressDetailsStep({ handleNext, handleBack, form }) {
  const dispatch = useDispatch();

  const { authLoading, authData } = useSelector((state) => state.auth);
  const { orgObj } = useSelector((state) => state.obj);

  const onSearchCountry = (value) => {};
  const filterOptionCountry = (input, option) =>
    (option?.children?.toLowerCase() ?? "").includes(input.toLowerCase());


  const country = citiesData?.find((city) => city.name === orgObj?.orgCountry);
  const [city, setcity] = useState(orgObj?.orgCountry ? country?.cities?.map((city) => city?.name):[]);

  function selectedCountry(value) {
    const country = citiesData?.find((city) => city.name === value);
    if (country && country?.cities && country?.cities?.length > 0) {
      const cityNames = country?.cities?.map((city) => city?.name);
      setcity(cityNames);
    }
  }

  async function handleSubmitStep() {
    try {
      await form.validateFields([
        "orgAddressType",
        "orgLine1",
        "orgLine2",
        "orgLine3",
        "orgPostalCode",
        "orgCountry",
        "orgCity",
        "orgState",
      ]);
      await handleNext();
    } catch {}
  }

  const handlePrev = async () => {
    await handleBack();
  };
  
  return (
    <>
      <div className="flex flex-col w-full  h-full ">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-[.5rem]">
          <Form.Item
            name="orgAddressType"
            className=""
            label={"Address Type"}
            rules={[
              {
                required: true,
                message: "Please select Address Types",
              },
            ]}
          >
            <Select
              className=""
              allowClear
              style={{
                width: "100%",
              }}
              options={
                addressTypeData?.length > 0 &&
                addressTypeData?.map((item) => ({
                  value: item?.value,
                  label: item?.label,
                }))
              }
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label?.toLocaleLowerCase() ?? "").includes(
                  input?.toLocaleLowerCase()
                )
              }
            />
          </Form.Item>

          <Form.Item
            label="Line 1"
            name="orgLine1"
            rules={[
              {
                required: true,
                message: "Line 1 is required",
              },
              {
                min: 3,
                max: 150,
                message: "Postal code must be >=3 and <=150 characters",
              },
            ]}
          >
            <Input className="input" />
          </Form.Item>
          <Form.Item
            label="Line 2"
            name="orgLine2"
            rules={[
              {
                required: false,
                message: "Line 2 is required",
              },
              {
                min: 3,
                max: 150,
                message: "Postal code must be >=3 and <=150 characters",
              },
            ]}
          >
            <Input className="input" />
          </Form.Item>
          <Form.Item
            label="Line 3"
            name="orgLine3"
            rules={[
              {
                required: false,
                message: "Line 3 is required",
              },
              {
                min: 3,
                max: 150,
                message: "Postal code must be >=3 and <=150 characters",
              },
            ]}
          >
            <Input className="input" />
          </Form.Item>

          <Form.Item
            label="Postal code"
            name="orgPostalCode"
            rules={[
              {
                required: false,
                message: "Postal code is required",
              },
              {
                min: 3,
                max: 10,
                message: "Postal code must be >=3 and <=10 characters",
              },
            ]}
          >
            <Input className="input" />
          </Form.Item>

          <Form.Item
            name="orgCountry"
            label="Country"
            rules={[
              {
                required: true,
                message: "Please select a country",
              },
            ]}
          >
            <Select
              showSearch
              style={{
                height: "3.25rem",
              }}
              className="reg-input"
              placeholder="Country"
              onChange={async (value) => {
                await selectedCountry(value);
                await form.setFieldsValue({ orgCity: undefined });
              }}
              size="large"
              optionFilterProp="children"
              onSearch={onSearchCountry}
              filterOption={filterOptionCountry}
            >
              {citiesData?.map((city) => (
                <Select.Option key={city?.name} value={city?.name}>
                  {city?.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="City"
            name="orgCity"
            rules={[
              {
                required: true,
                message: "Please select a city",
              },
            ]}
          >
            <Select
              showSearch
              style={{
                height: "3.25rem",
              }}
              disabled={city?.length ? false : true}
              placeholder={"City"}
              size="large"
              className="reg-input"
              optionFilterProp="children"
              onSearch={onSearchCountry}
              filterOption={filterOptionCountry}
            >
              {city?.map((city) => {
                return (
                  <Select.Option required key={city} value={city}>
                    {city}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            label="State"
            name="orgState"
            rules={[
              {
                required: true,
                message: "State is required",
              },
              {
                min: 3,
                max: 50,
                message: "Postal code must be >=3 and <=50 characters",
              },
            ]}
          >
            <Input className="input" />
          </Form.Item>
        </div>

        <div className="pt-[1.44rem] pb-[2.44rem] w-full flex justify-center items-center">
          <div className="w-[10.625rem] flex flex-col gap-[.5rem]">
            <button
              disabled={authLoading}
              onClick={handleSubmitStep}
              type="button"
              className="cstm-btn-2 !w-[200px]"
            >
              {authLoading ? <Spin /> : "Next"}
            </button>
            <button onClick={handlePrev} type="button" className="cstm-btn !w-[200px]">
              Previous
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
