import { message } from "antd";
import axios from "axios"; 
 
const url = process.env.REACT_APP_API_BASE_URL;

export const handleLogout = async () => {
	await message.info("Session has expired. Log in to continue")
	await localStorage.clear()
	window.location = await "./login"
	await window.location.reload()
};

const axiosInstance = axios.create({
    baseURL: url,
    headers: {
        'Content-Type': 'application/json',
    },
});



axiosInstance.interceptors.response.use(response => {
    // console.log(response.headers);	
	
    return response;
}, (error) => { 
		const statusCode = error.response ? error.response.status : null;
 

		if (statusCode == 403) {
			handleLogout();
		}

		// if (statusCode == 401) {
		// 	handleLogout();
		// } 
		

		return Promise.reject(error);
	});

export default axiosInstance;