import { Dropdown, Input, Modal, Table } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import svg27 from "../../../assets/svg/svg27.svg";
import DeleteModal from "../../../components/DeleteModal";
import Search from "../../../components/Search";
import CardLoading from "../../../components/CardLoading";
import { fetchGroupMembers } from "../../../features/fetch/fetchSlice";
import AddGroupContactModal from "./modal/AddGroupContactModal";
import toast from "react-hot-toast";
import { deleteGroupMember } from "../../../features/delete-setting/deleteSlice";
import { cashConverter } from "../../../utils";

export default function ContactsListView({ rowId,setRowId }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, groupMembers } = useSelector(
    (state) => state.fetch
  );
  const { deleteLoading} = useSelector((state) => state.setting);

  const { activeObj } = useSelector((state) => state.obj);

  const { saving } = useSelector((state) => state.save); 

  const [searchInput, setsearchInput] = useState("");

  const handleSearch = (e) => {
    setsearchInput(e.target.value); 
  };

  const filteredContacts = searchInput
    ? groupMembers?.filter(
        (item) =>
          item?.pgdFirstName?.toLowerCase().includes(searchInput.toLowerCase()) ||
          item?.pgdLastName?.toLowerCase().includes(searchInput.toLowerCase()) ||
          item?.pgdNationalId?.toLowerCase().includes(searchInput.toLowerCase())
      )
    : groupMembers;

  const [prodd, setProdd] = useState("");
  const [isModalOpenDelete, setIsModalOpenDelete] = useState(false);
  const showModalDelete = async () => {
    setIsModalOpenDelete(true);
  };
 
  const handleDelete = async () => {
    const res = await dispatch(
      deleteGroupMember({
        pgdId:prodd?.pgdId
      })
    );
    if (res?.payload?.success) {
      await toast.success(res?.payload?.messages?.message);
      handleFetchData();
      await setIsModalOpenDelete(false);
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  };

  function handleGoBack() {
    navigate(-1);
  }

  async function handleActiveGraduate(item) {
    // await dispatch(setGraduateObj(item));
    // await setactiveGraduate(item);
  }

  const [openSingle, setopenSingle] = useState(false);
  async function handleOpenSingle() {
    await setopenSingle(true);
  }
  async function handleCancelSingle() {
    await setopenSingle(false);
  }

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  // const [rowId, setRowId] = useState([]);

  const handleEmployeeToReturns = async (selectedRows) => {
    setRowId(selectedRows);
  };

 

  const onSelectChange = (keys, rows) => {
    setSelectedRowKeys(keys);
    handleEmployeeToReturns(rows);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleEdit = async () => {
    await setopenSingle(true);
  };

  const settingItems = [
    {
      key: "0",
      label: (
        <Link
          onClick={handleEdit}
          className="flex  text-[16px] font-sans  !text-darkGreen"
        >
          Edit
        </Link>
      ),
    },

    {
      key: "1",
      label: (
        <div
          onClick={showModalDelete}
          className="flex  text-[16px] font-sans  !text-darkGreen"
        >
          Remove
        </div>
      ),
    },
  ];

 
  const columns = [
    {
      title: "First Name",
      render: (item) => <span>{item?.pgdFirstName}</span>,
      sorter: (a, b) => a.pgdFirstName.localeCompare(b.pgdFirstName),
    },
    {
      title: "Last Name",
      render: (item) => <span>{item?.pgdLastName}</span>,
      sorter: (a, b) => a.pgdLastName.localeCompare(b.pgdLastName),
    },
    {
      title: "ID No.",
      dataIndex: "pgdNationalId",
      sorter: (a, b) => a.pgdNationalId.localeCompare(b.pgdNationalId),
    },
    {
      title: "Payment Mode",
      dataIndex: "pgdPaymentMode",
      sorter: (a, b) => a.pgdPaymentMode.localeCompare(b.pgdPaymentMode),
    },

    {
      title: "Receiver Mobile No",
      dataIndex: "pgdReceiverMobileNo",
      sorter: (a, b) =>
        a.pgdReceiverMobileNo.localeCompare(b.pgdReceiverMobileNo),
    },

    {
      title: "Account Number",
      dataIndex: "pgdBeneficiaryAccount",
      sorter: (a, b) =>
        a.pgdBeneficiaryAccount.localeCompare(b.pgdBeneficiaryAccount),
    },
    {
      title: "Amount", 
      dataIndex: "pgdAmount",
      render:(item)=>{
        return(
          <div>{cashConverter(item)}</div>
        )
      },
      sorter: (a, b) => a.pgdAmount.localeCompare(b.pgdAmount),
    },

    {
      title: "",
      render: (item) => (
        <>
          <Dropdown
            onOpenChange={() => handleActiveGraduate(item)}
            overlayStyle={{
              width: "200px",
            }}
            trigger={"click"}
            menu={{ items: settingItems }}
            placement="bottom"
          >
            <button onClick={() => setProdd(item)}>
              <img src={svg27} alt="svg27" />
            </button>
          </Dropdown>
        </>
      ),
    },
  ];

 

  async function handleFetchData() {
    await dispatch(
      fetchGroupMembers({
        url: "api/v1/payments/fetch-payment-group-details",
        pgdPgId: activeObj?.pgId,
      })
    );
  }

  // useEffect(() => {
  //   handleFetchData();
  // }, []);

  // if (loading) return <CardLoading />;

  return (
    <>
      <div className="mt-[35px]">
        <div className="flex lg:flex-row flex-col gap-y-5 justify-between lg:items-center items-start w-[100%]">
          <div className="w-[16.8125rem]">
            <Search
              searchInput={searchInput}
              handleSearch={handleSearch}
              text={"Search contact..."}
            />
          </div>
        </div>

        <div className="text-black1 mt-[45px] mb-[21px] text-[20px] font-medium leading-[24px]">
        Select members to make  payments
         </div>

        <Table
          rowSelection={rowSelection}
          loading={loading}
          className="w-full"
          columns={columns}
          dataSource={filteredContacts}
          scroll={{
            x: 1100,
          }}
          rowKey={(record) => record?.pgdId}
        />
      </div>
 

      <DeleteModal
        isModalOpen={isModalOpenDelete}
        setIsModalOpen={setIsModalOpenDelete}
        prodd={prodd}
        handleDelete={handleDelete}
        loading={deleteLoading}
        content={`Are you sure you want to delete ${prodd?.pgdFirstName} ${prodd?.pgdLastName}?`}
        title={`Remove ${prodd?.pgdFirstName} ${prodd?.pgdLastName}`}
      />

      <AddGroupContactModal
        open={openSingle}
        handleFetchData={handleFetchData}
        handleCancel={handleCancelSingle}
        prodd={prodd}
      />
    </>
  );
}
