import { Descriptions, Modal, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import OtpModal from "./OtpModal";
import { save } from "../../../../features/save/saveSlice";
import toast from "react-hot-toast"; 
import { cashConverter } from "../../../../utils";

function VerificationModal({
  open,
  handleCancel,
  handleFetchData,
  setopenVerification,
  handleCancelSingle,
}) {
  const { singlePaymentObj,memberObj } = useSelector((state) => state.obj);
  const { saving } = useSelector((state) => state.save);
  
  const { grpTotals} = useSelector((state) => state.fetch);
  
  const dispatch = useDispatch();

  const [openOtp, setopenOtp] = useState(false);
  async function handleCancelOtp() {
    await setopenOtp(false);
    await setopenVerification(true);
  }

  async function handleCancelAll() {
    await setopenOtp(false);
    await setopenVerification(false);
    await handleCancelSingle();
  }

  async function handleOpenOtp() {
    await setopenOtp(true);
    await setopenVerification(false);
  }

  const onFinish = async (data) => {
    const res = await dispatch(
      save({
        url: "api/v1/payments/send-payment-otp",
      })
    );
    if (res?.payload?.success) {
      await toast.success(res?.payload?.messages?.message);
      await handleOpenOtp();
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  };

 
  const items = [
    {
      key: "1",
      label: "Payment Source",
      children: singlePaymentObj?.paymentSourceName,
    },
    
    {
      key: "2",
      label: "Reason",
      children: singlePaymentObj?.pgReason,
    },
    
  
    {
      key: "4",
      label: "Amount",
      children:<div>{cashConverter(grpTotals)}</div> ,
    },
    
  ];
  
 
  return (
    <>
      <Modal
        className="modal-btn mod-height mod-footer"
        title={`Verification`}
        open={open}
        onOk={onFinish}
        onCancel={handleCancel}
        width={850}
        maskClosable={false}
      >
        <p className="text-[15px] text-[#000] font-[600] mb-8 font-dmSans">
          Would you like to make the following transfer of {cashConverter(grpTotals)} ? 
        </p>

        <div className="mt-[1.25rem]">
            <Descriptions
              column={1}
              colon={false}
              title="Group Payment Information"
              items={items}
            />
         
        </div>

        <div className="flex lg:flex-row flex-col justify-between mt-[56px] mb-[48px]">
          <div className="justify-start"></div>
          <div className="justify-end flex items-center gap-x-2">
            <div className="w-[150px]">
              <button
                key="back"
                type="button"
                onClick={handleCancel}
                className="cstm-btn"
              >
                Previous
              </button>
            </div>

            <div className="w-[150px]">
              <button
                key="submit"
                type="submit"
                className="cstm-btn-2"
                onClick={onFinish}
                disabled={saving}
              >
                {saving ? <Spin /> : "Next"}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <OtpModal
        handleFetchData={handleFetchData}
        open={openOtp}
        handleCancel={handleCancelOtp}
        handleCancelAll={handleCancelAll}
      />
    </>
  );
}

export default VerificationModal;
